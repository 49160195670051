import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body{
    font-family: 'Archivo', sans-serif;
    background-color: #f5f5f5;
  }

  input, button, textarea{
    font-family: 'Archivo', sans-serif;
    ::-moz-placeholder{
    color: #474747;
  }
  }

  ul{
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 1;
  }

  button{
    cursor: pointer;
  }

  a { 
    color: inherit;
    text-decoration:none;
  } 

  #root{
    display: flex;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    justify-content: space-between;
  }

  @media (max-height: 500px){
    #root{
      height: 668px;
    }
  }
`;