import styled, {keyframes} from 'styled-components';

const formAnimate = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-60px);
  }
  100% {
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  display: ${props => props.setActive};
  justify-content: center;
  align-items: center;
  background-color: rgba(80, 80, 80, 0.2);
  padding: 30px;
`;

export const ContainerModal = styled.div`
  width: 40vw;
  min-width: 400px;
  max-height: 80vh;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 10px #c4c4c4;
  overflow-x: auto;
  animation: ${formAnimate} 1s;
`;

export const ContainerTitleModal = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: 10px 10px 0 0;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Titillium Web';
  font-weight: 600;
  font-size: 20px;
  color: ${props => props.theme.colors.primary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.button};
`;

export const ContainerBodyModal = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  font-family: 'Titillium Web';
`;

export const CloseWindow = styled.button`
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 10px;
  display: flex;
  align-items:center ;
  justify-content: center;
  background-color: ${props => props.theme.colors.white};

  :hover{
    background-color: ${props => props.theme.colors.button};
    color: ${props => props.theme.colors.primary};
  }
`