import { Button } from '@mui/material';
import MenuContainer from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { FaDesktop } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

export default function Menu(props) {
  const unidade = props.unidade
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  async function navigateToPainel(tipoPainel = 3) {    
    let route = `/senhas/${unidade.id}/${tipoPainel}`;
    if (unidade.token) {
      route = route + `/?token=${unidade.token}`;
    }
    navigate(route)
  }

  return (
    <>
      <Button 
        id="button"
        aria-controls={open ? 'menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <FaDesktop />
      </Button>
      <MenuContainer
        id="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'button',
        }}
      >
        <MenuItem onClick={() => {navigateToPainel(1)}}>Senhas Recepção</MenuItem>
        <MenuItem onClick={() => {navigateToPainel(2)}}>Senhas Coleta</MenuItem>
        <MenuItem onClick={() => {navigateToPainel(3)}}>Todas as senhas</MenuItem>
      </MenuContainer>
    </>
  );
}