import { START_CRONOMETER,END_CRONOMETER } from '../actionTypes';

export function startCronometer(){
  return{
    type: START_CRONOMETER,
    payload: true
  }
}

export function endCronometer(){
  return{
    type: END_CRONOMETER,
    payload: false
  }
}