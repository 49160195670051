import { useMemo, useEffect, useState } from "react";
import {
  AiOutlineDoubleLeft,
  AiOutlineLeft,
  AiOutlineDoubleRight,
  AiOutlineRight,
} from 'react-icons/ai'
import Limit from "./limit";
import { Container, Icon, Pages } from "./styles";

export { Limit };

export function Pagination(props) {
  const INITIAL_PAGE = 1;
  const { handleOnClickPage, count, limit } = props;
  const [page, setPage] = useState(INITIAL_PAGE);
  const numOfPages = useMemo(() => Math.ceil(count / limit) || 1, [limit, count]);

  function previous() {
    const previousPage = page - 1;
    const offset = (previousPage - 1) * limit;
    handleOnClickPage(limit, offset);
    setPage(previousPage);
  }

  function next() {
    const nextPage = page + 1;
    const offset = page * limit;
    handleOnClickPage(limit, offset);
    setPage(nextPage);
  }

  function lastPage() {
    const offset = (numOfPages - 1) * limit;
    handleOnClickPage(limit, offset);
    setPage(numOfPages);
  }

  function firstPage() {
    handleOnClickPage(limit, 0);
    setPage(1);
  }

  useEffect(() => {
    firstPage();
  }, [limit]);

  return (
    <Container>
      <Pages>
        <Icon
          disabled={page === INITIAL_PAGE}
          onClick={() => page !== INITIAL_PAGE ? firstPage() : undefined}>
          <AiOutlineDoubleLeft size={22} />
        </Icon>
        <Icon
          disabled={page === INITIAL_PAGE}
          onClick={() => page !== INITIAL_PAGE ? previous() : undefined}>
            <AiOutlineLeft size={22} />
        </Icon>
        {page} de {numOfPages}
        <Icon
          disabled={page === numOfPages}
          onClick={() => page !== numOfPages ? next() : undefined}>
          <AiOutlineRight size={22} />
        </Icon>
        <Icon
          disabled={page === numOfPages}
          onClick={() => page !== numOfPages ? lastPage() : undefined}>
          <AiOutlineDoubleRight size={22} />
        </Icon>
      </Pages>
    </Container>
  );
}