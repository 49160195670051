import styled from "styled-components";

export const Content = styled.div`
  margin-left: 2%;
  width: 180px;
  min-width: 180px;
  height: 3.5rem;
  min-height: 40px;
  background-color: ${({ theme }) => theme.colors.secondary};
  opacity: ${({ isActive }) => (isActive === 1 ? 0.6 : 1)};
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 10px;
  font-size: 26px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.white};
  transition: 0.2s;
  @media (min-width: 1400px) {
    margin-left: 4%;
  }
`;
