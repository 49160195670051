import styled from "styled-components";

export const Content = styled.div`
  width: 80px;
  height: 30px;
  margin-bottom: 20px;
  background-color: ${(props) =>
    props.active === true ? "#6ECFBD" : "#E23636"};
  position: relative;
  border-radius: 20px;
  transition: 0.2s;
  z-index: 2;
  :hover {
    box-shadow: 0 0 0 3px
      ${(props) =>
        props.active === true
          ? "rgba(126, 217, 87, 0.6)"
          : "rgba(226, 54, 54, 0.6)"};
  }
`;

export const Circle = styled.div`
  width: 24px;
  height: 24px;
  margin: 2px;
  border-radius: 100%;
  border: 2px solid #fff;
  background-color: ${(props) =>
    props.active === true ? "#6ECFBD" : "#B90000"};
  right: ${(props) => (props.active === true ? "2px" : null)};
  left: ${(props) => (props.active === true ? null : "2px")};
  position: absolute;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.div``;
