import { MdKeyboardArrowDown } from "react-icons/md";
import { Select, SelectContainer, SelectContent, SelectLabel } from "./styles";

export default function LimitWrapper({limit, onLimitChange}) {
  const LIMITS = [5, 10, 20, 50];

  return (
    <SelectContent>
      <SelectLabel>Resultados por página</SelectLabel>
      <SelectContainer>
        <Select
          bgColor="inherit"
          label="Resultados por página"
          value={limit}
          onChange={e => onLimitChange(Number(e.target.value))}>
          {LIMITS.flatMap(l => <option key={l.toString()} value={l}>{l}</option>)}
        </Select>
        <MdKeyboardArrowDown />
      </SelectContainer>
    </SelectContent>
  );
}