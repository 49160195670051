import React, { useEffect } from "react";
import Cookie from "js-cookie";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Form, MessageError } from "../../../styles";

import { FaEdit } from "react-icons/fa";

import { AiOutlineNumber } from "react-icons/ai";
import CustomSelect from "react-select";

import Api from "../../../../../services/apiService";
import Modal from "../../../../../components/Modal";
import Input from "../../../../../components/Forms/Input";
import Select from "../../../../../components/Forms/Select";
import Button from "../../../../../components/Forms/Button";
import { toast } from "../../../../../utils/toast";
import { useTheme } from "styled-components";
import { CgArrowsV } from "react-icons/cg";
import { FiMapPin, FiMonitor } from "react-icons/fi";
import { BiToggleRight } from "react-icons/bi";
import CheckBox from "../../../../../components/Forms/Checkbox";

export default function Counter({
  isUpdateCounter,
  isCreateGuiche,
  setToLoad,
  closeWindowModal,
  unit,
  counter,
  tipoAtendimentos,
  queues,
}) {
  const theme = useTheme();

  const defaultValues = {
    name: "",
    description: "",
    attendanceKind: "",
    range: "",
    queues: [],
    status: "0",
    limit_to_the_linked_queues: false,
  };

  const schema = yup.object().shape({
    name: yup.string().required("Nome do guichê obrigatório."),
    description: yup.string().required("Descrição obrigatória."),
    attendanceKind: yup.string().required("Tipo de atendimento obrigatório."),
    queues: yup.array().of(
      yup.object().shape({
        id: yup.string(),
        nome_fila: yup.string(),
      })
    ),
    status: yup.string(),
    range: yup
      .number()
      .integer("Informe uma quantidade positiva")
      .typeError("Informe um número"),
    limit_to_the_linked_queues: yup
      .boolean()
      .required("Informe a necessidade de chamar apenas as filas relacionadas."),
  });

  const {
    control,
    reset,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  useEffect(() => {
    clearErrors();
    if (isUpdateCounter) {
      setValue("name", counter.nome_guiche);
      setValue("description", counter.desc_guiche);
      setValue("attendanceKind", counter.atendimento?.id || "");
      setValue("range", counter.quantidade_atendimentos || 0);
      setValue(
        "queues",
        queues.filter((q) => (counter.filas || []).includes(q.id)) || []
      );
      setValue("status", counter.status || "0");
      setValue(
        "limit_to_the_linked_queues",
        counter.limit_to_the_linked_queues,
      );
    } else {
      reset();
    }
  }, [isUpdateCounter, counter]);

  async function onSubmitGuiche(data) {
    try {
      let newData = {
        nome_guiche: data.name,
        desc_guiche: data.description,
        tipo_atendimento_id: data.attendanceKind,
        limit_to_the_linked_queues: data.limit_to_the_linked_queues,
        unidade_id: unit,
        quantidade_atendimentos: data.range,
        filas: data.queues.map((q) => q.id),
      };

      await Api.post("/guiches", newData);
      toast("success", "Excelente", "O guichê foi criado com sucesso!");
      closeWindowModal();
      setToLoad(true);
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Ocorreu um erro ao criar uma unidade"
      );
    }
  }

  async function onSubmitCounterUpdate(dataPayload) {
    try {
      const payload = {
        nome_guiche: dataPayload.name,
        desc_guiche: dataPayload.description,
        quantidade_atendimentos: dataPayload.range,
        filas: dataPayload.queues.map((q) => q.id),
        status: dataPayload.status,
        limit_to_the_linked_queues: dataPayload.limit_to_the_linked_queues,
      };

      const { data } = await Api.put(`/guiches/${counter.id}`, payload);
      toast(
        "success",
        "Sucesso",
        data?.message || "O guichê foi atualizado com sucesso!"
      );
      closeWindowModal();
      setToLoad(true); // TODO: Change reload method
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Não foi possível atualizar o guichê"
      );
    }
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: `#333333`,
      backgroundColor: "transparent",
      boxShadow: "none",
      borderRadius: "8px",
      padding: "0.3rem",
      paddingLeft: "30px",
      margin: "5px 0px",

      "::placeholder": {
        color: "hsl(0, 0%, 50%)",
      },
      ":hover": {
        borderColor: "#0280F8",
      },
      ":active, :focus": {
        borderColor: "#0280F8",
        boxShadow: "0px 0px 6px 0px #0280F8",
      },
    }),
    container: (provided, state) => ({
      ...provided,
      zIndex: "auto",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: `#0280F8`,
      svg: {
        fill: "#0280F8",
      },
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    menu: (provided, state) => ({
      ...provided,
      width: "38%",
      position: "fixed",
      top: "auto",
      marginTop: "-5px",
      zIndex: 9999,
      padding: "5px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
      borderLeft: state.isSelected ? `3px solid #0280F8` : "none",
      color: state.isSelected ? `#0280F8` : "inherit",

      "&:hover, &:active": {
        borderLeft: `3px solid #0280F8`,
        color: `#0280F8`,
        backgroundColor: "white",
      },
    }),
  };

  return (
    <Modal
      modalWidth={50}
      modalHeight={90}
      modalTitle={(isUpdateCounter ? "Editar" : "Cadastrar") + " guichê"}
      modalActive={isCreateGuiche}
      modalSetActive={() => closeWindowModal()}
    >
      <Form>
        <Controller
          control={control}
          name="name"
          render={({ field: { onChange, value } }) => (
            <Input
              LeftIcon={<FiMonitor size={22} />}
              width="100"
              height="10"
              TextInput="Digite o nome do Guichê"
              typeInput={"text"}
              handleChange={onChange}
              value={value}
              errorMessage={errors?.name ? errors?.name.message : null}
            />
          )}
        />
        <Controller
          control={control}
          name="description"
          render={({ field: { onChange, value } }) => (
            <Input
              LeftIcon={<FaEdit size={22} />}
              width="100"
              height="10"
              TextInput="Digite uma breve Descrição do guichê"
              typeInput={"text"}
              handleChange={onChange}
              value={value}
              errorMessage={
                errors?.description ? errors?.description.message : null
              }
            />
          )}
        />
        <Controller
          control={control}
          name="attendanceKind"
          render={({ field: { onChange, value } }) => (
            <Select
              bgColor={theme.colors.white}
              LeftIcon={<FiMapPin size={22} />}
              defaultTitle="Selecione o tipo de atendimento"
              handleChange={onChange}
              value={value}
              errorMessage={
                errors?.attendanceKind ? errors?.attendanceKind.message : null
              }
            >
              {tipoAtendimentos.length > 0
                ? tipoAtendimentos.map((atendimento, index) => {
                    return (
                      <option key={index} value={atendimento.id}>
                        {atendimento.tipo_atendimento}
                      </option>
                    );
                  })
                : null}
            </Select>
          )}
        />
        <Controller
          control={control}
          name="range"
          render={({ field: { onChange, value } }) => (
            <Input
              width="100"
              height="10"
              LeftIcon={<AiOutlineNumber size={22} />}
              handleChange={onChange}
              value={value}
              valueStep={1}
              valueMin={0}
              typeInput="number"
              TextInput="Informe a quantidade de atendimento"
              errorMessage={errors?.range ? errors.range?.message : null}
            />
          )}
        />
        <Controller
          control={control}
          name="queues"
          render={({ field: { onChange, value } }) => (
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                <CgArrowsV
                  style={{
                    marginLeft: "10px",
                    marginRight: "-32px",
                  }}
                />
                <div style={{ width: "100%" }}>
                  <CustomSelect
                    isMulti
                    value={value}
                    options={queues}
                    onChange={onChange}
                    styles={customStyles}
                    placeholder="Selecione uma fila/prioridade"
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.nome_fila}
                    noOptionsMessage={() => "Não foram encontradas filas"}
                  />
                </div>
              </div>
              {errors.queues?.message ? (
                <MessageError>{errors.queues?.message}</MessageError>
              ) : null}
            </>
          )}
        />
        {!isUpdateCounter || (isUpdateCounter && !counter.uso_compartilhado) ? (
          <Controller
            control={control}
            name="limit_to_the_linked_queues"
            render={({ field: { onChange, value } }) => (
              <CheckBox
                label="Restringir apenas as filas selecionadas"
                handleCheckBoxChange={onChange}
                checked={value}
                errrorMessage={errors?.default?.message}
              />
            )}
          />
        ) : null}
        {isUpdateCounter ? (
          <Controller
            control={control}
            name="status"
            render={({ field: { onChange, value } }) => (
              <Select
                width="100"
                height="10"
                value={value}
                placeholder="Status"
                LeftIcon={<BiToggleRight size={22} />}
                defaultTitle="Selecione um status"
                handleChange={onChange}
                errrorMessage={errors?.status?.message}
              >
                <option value="0">Ativo</option>
                <option value="2">Inativo</option>
              </Select>
            )}
          />
        ) : null}
        <Button
          width={30}
          height={10}
          text={isUpdateCounter ? "Atualizar" : "Cadastrar"}
          typeButton="button"
          HandleClick={handleSubmit(
            isUpdateCounter ? onSubmitCounterUpdate : onSubmitGuiche
          )}
        />
      </Form>
    </Modal>
  );
}
