import React from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";
import { useSelector } from "react-redux";
import Login from "./pages/Login";
import ESenha from "./pages/ESenha";
import Clinic from "./pages/Clinic";
import SelectValidateESenha from "./pages/SelectValidate";
import InfoESenha from "./pages/InfoESenha";
import Unit from "./pages/SelectUnit";
import Attendance from "./pages/Attendance";
import SharedAttendance from "./pages/SharedAttendance";
import Admin from "./pages/Admin";
import UpdateUnits from "./pages/Admin/Panels/UpdateUnits";
import Key1 from "./pages/Keys/1";
import Key2 from "./pages/Keys/2";
import Key3 from "./pages/Keys/3";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Profile from "./pages/Admin/Panels/Profile";
import { ADMIN, ATTENDANT, MANAGER, SUPERVISOR } from "./utils/constants";

function hasPermission(perfil, permissions) {
  return perfil.filter((p) => permissions.includes(p)).length > 0;
}

function hasObjectPermission(units, currentUnit) {
  if (!currentUnit) return true;
  return units.some(unit => unit.id === currentUnit);
}

function RedirectManage(props) {
  const params = useParams();
  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  const toRoute = user?.id ? props.toRoute : `/?returnTo=${location.pathname}`;

  return user?.id && (
    user.isadmin || (
      hasPermission(user.perfil, props.permissions)
      && hasObjectPermission(user.unidades, params.id)
    )
  ) ? (
    props.element
  ) : (
    <Navigate replace to={toRoute} state={{ location }} />
  );
}

function AuthenticatedRoute() {
  const location = useLocation();
  const { user } = useSelector((state) => state.user);

  const queryParams = new URLSearchParams(location.search);
  const returnTo = queryParams.get('returnTo');

  return !user?.id ? (
    <Login />
  ) : (
    <Navigate
      replace
      to={
        returnTo
        ? returnTo
        : (
          hasPermission(user.perfil, [ADMIN, MANAGER, SUPERVISOR])
            ? "/admin"
            : "/unidade"
        )
      }
      state={{ location }}
    />
  );
}

export default function Router() {

  return (
    <Routes>
      <Route path="/" element={<AuthenticatedRoute />} />
      {/* e-senha */}
      <Route path="/e-senha" element={<ESenha />} />

      <Route path="/validar-senha/:id" element={<SelectValidateESenha />} />
      <Route path="/info-senha/:id" element={<InfoESenha />} />

      <Route path="/totem/:id" element={<Clinic />} />
      {/* Tipos de paineis
        * [1] - Somente recepção.
        * [2] - Somente coleta.
        * [3] - Todas.
      */}
      <Route path="/senhas/:id" element={<Key3 />} />
      <Route path="/senhas/:id/1" element={<Key1 />} />
      <Route path="/senhas/:id/2" element={<Key2 />} />
      <Route path="/senhas/:id/3" element={<Key3 />} />
      <Route path="/senha/:id" element={<Key3 fromKeyId />} />
      <Route
        path="/unidade"
        element={
          <RedirectManage
            element={<Unit />}
            permissions={[ADMIN, MANAGER, ATTENDANT]}
            toRoute="/admin"
          />
        }
      />
      <Route
        path="/unidade/:id/atendimento/:counterId"
        element={
          <RedirectManage
            element={<Attendance />}
            permissions={[ADMIN, MANAGER, ATTENDANT]}
            toRoute="/admin"
          />
        }
      />
      <Route
        path="/compartilhado/:id"
        element={
          <RedirectManage
            element={<SharedAttendance />}
            permissions={[ADMIN, MANAGER, ATTENDANT]}
            toRoute="/admin"
          />
        }
      />
      <Route
        path="/admin"
        element={
          <RedirectManage
            element={<Admin />}
            permissions={[ADMIN, MANAGER, SUPERVISOR]}
            toRoute="/unidade"
          />
        }
      />
      <Route
        path="/admin/unidade/:id"
        element={
          <RedirectManage
            element={<UpdateUnits />}
            permissions={[ADMIN, MANAGER, SUPERVISOR]}
            toRoute="/unidade"
          />
        }
      />
      <Route path="/profile" element={<Profile isAdmin={false} />} />
      <Route path="/esqueceu" element={<ForgotPassword />} />
      <Route path="/resetar/:token" element={<ResetPassword />} />
    </Routes>
  );
}
