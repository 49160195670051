import React from "react";
import Lottie from "react-lottie";

import animationLoading from '../../assets/animationLoading.json';

import { 
  Content,
  ModalLoading
} from "./styles";

export default function Loading({
  setDisplay = 'none'
}){

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationLoading,
  }

  return(
    <Content setDisplay={setDisplay}>
      <ModalLoading>
        <Lottie 
          options={defaultOptions}
        />
      </ModalLoading>
    </Content>
  )
}