import styled, {css} from "styled-components";

import { FaUsers } from 'react-icons/fa'
import { MdKeyboardArrowRight } from 'react-icons/md'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Button = styled.button`
  border-radius: 16px;
  border: none;
  background-color: ${props => props.theme.colors.primary};
  transition: 0.3s;
  padding: 45px 36px;
  margin: 16px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 26px;
  font-weight: 500;
  color: ${ props => props.theme.colors.white };

  :disabled {
    pointer-events: none;
    opacity: 0.3;
  }
`

export const ILeft = styled(FaUsers).attrs({
  size: 32
})``

export const IRight = styled(MdKeyboardArrowRight).attrs({
  size: 32,
  color: "#fff",
})``