import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const IdUser = styled.div`
  width: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
`

export const NameUser = styled.div`
  /* width: 30%; */
  /* height: 100%; */
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  /* font-weight: 700; */
`

export const CargoUser = styled.div`
  width: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
`

export const OptionsUser = styled.div`
  /* width: 20%; */
  /* height: 100%; */
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
`

export const Units = styled.div`
  /* width: 15%; */
  /* height: 100%; */
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
`

export const Icons = styled.div`
  /* width: 20%;
  height: 70%; */
  background-color: ${ props => props.setColor };
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  padding: 7px;
  transition: 0.1s;
  :hover{
    opacity: 0.8;
  }
`

export const UnitsUser = styled.div`
  width: 20%;
  height: 70%;
  background-color: ${ props => props.theme.colors.primary };
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.1s;
  :hover{
    opacity: 0.8;
  }
`