import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Cookies from "js-cookie";
import {
  ContentGlobal,
  LayerTitle,
  LayerButtons,
  LinkButtonAdmin,
  ButtonAdminDescribe,
  FilterForm,
  Rows,
  RowItem,
  RowInfo,
  Item,
  Header,
  ButtonHeader,
  ButtonTitle,
  ContentButton,
  ContentInput,
  Label,
  MessageError,
} from "../styles";
import {
  closeLoadingModal,
  openLoadingModal,
} from "../../../store/Actions/loadingAction";
import Api from "../../../services/apiService";
import { toast } from "../../../utils/toast";
import { addDays, getDate } from "../../../utils";
import { getTime } from "../../../utils/format";
import { ListHeader } from "../../../components/ListHeader";
import { formatDate } from "../../../utils/format";
import Select from "react-select";
import Input from "../../../components/Forms/Input";
import { MdBusiness, MdCalendarToday } from "react-icons/md";
import Button from "../../../components/Forms/Button";
import store from "../../../store/storeConfig";
import { logOut } from "../../../store/Actions/userAction";
import { viewHome } from "../../../store/Actions/panelAction";

export default function Home() {
  const today = new Date();
  const schema = yup.object().shape({
    unit: yup.object().shape({
      label: yup.string(),
      value: yup.string().required("Campo obrigatório"),
    }),
    initialDate: yup
      .date()
      .max(today, "Não existe informações futuras")
      .required("Data inicial obrigatória"),
    finalDate: yup
      .date()
      .max(today, "Não existe informações futuras")
      .required("Data final obrigatória"),
  });

  const defaultCounterFilter = { counterId: undefined, counterName: "Padrão" };

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { unidade } = useSelector((state) => state.clinic);
  const [link, setLink] = useState("");
  const [units, setUnits] = useState([]);
  const [counter, setCounter] = useState(defaultCounterFilter);
  const [passwords, setPasswords] = useState([]);

  const defaultValues = {
    unit: "",
    initialDate: getDate(today),
    finalDate: getDate(today),
  };

  function getHref() {
    const Href = window.location.href.toString();
    setLink(Href.split("/admin"));
  }

  async function loadUnits() {
    try {
      dispatch(openLoadingModal());
      const { data } = await Api.get("/unidades", { params: { status: "0", limit: "50" } })
      setUnits(
        data.map((item) => {
          return { label: item.unidade, value: item.id };
        })
      );
    } catch (error) {
      if (error?.response?.status === 401) {
        store.dispatch(logOut());
        store.dispatch(viewHome());
        Cookies.remove('user');
        Cookies.remove('token');
        Cookies.remove('userName');
      } else{
        toast(
          "error",
          "Erro",
          error?.response?.data?.message ||
            "Não foi possível carregar as unidades."
        );
      }
    }
    dispatch(closeLoadingModal());
  }

  async function loadPasswords({ unit, initialDate, finalDate }) {
    try {
      dispatch(openLoadingModal());
      const { data } = await Api.get("/senhas", {
        params: {
          unidade_id: unit.value,
          data_inicio: getDate(initialDate),
          data_fim: addDays(getDate(finalDate), 1),
          limit: 1000000,
        },
      });
      setCounter(defaultCounterFilter);
      setPasswords(data);
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Não foi possível buscar as senhas"
      );
    }
    dispatch(closeLoadingModal());
  }

  async function onSubmit(payload) {
    await loadPasswords(payload);
  }

  useEffect(() => {
    loadUnits();
    getHref();
  }, []);

  useEffect(() => {
    if (units && units[0]) setValue("unit", units[0]);
  }, [units]);

  function formatDecimalNumber(number) {
    number = number.toString();
    if (number.length === 1) return `0${number}`;
    return number;
  }

  function formattedTime(diff) {
    const hours = parseInt(diff / 60 / 60 / 1000);
    diff = diff - hours * 60 * 60 * 1000;
    const minutes = parseInt(diff / 60 / 1000);
    diff = diff - minutes * 60 * 1000;
    const seconds = parseInt(diff / 1000);
    return (
      formatDecimalNumber(hours) +
      `:${formatDecimalNumber(minutes)}:` +
      formatDecimalNumber(seconds)
    );
  }

  function getDiff(date1, date2) {
    if (!date1 || !date2) return "00:00:00";
    date1 = new Date(date1);
    date2 = new Date(date2);
    const diff = date2.getTime() - date1.getTime();
    return formattedTime(diff);
  }

  function calcWaitTimeAtCounter(key) {
    const initDate = key.data_chamada;
    const endDate = key?.horario_encaminhamento
      ? key.horario_encaminhamento
      : key.data_hora_inicio_atendimento;

    return getDiff(initDate, endDate);
  }

  const avgData = useMemo(() => {
    let amount = 0;
    let amountCalled = 0;
    let lostPasswords = 0;
    let attendanceTimeSum = 0;
    let availableEstimateCount = 0;
    let reactivateCount = 0;
    for (let password of passwords) {
      if (
        !counter?.counterId ||
        (counter.counterId && password.guiche_id === counter.counterId)
      ) {
        amount++;
        if (!!password.data_chamada) amountCalled++;
        if (password.reativada) reactivateCount++;
        if (!!password.data_hora_finalizacao_atendimento) {
          availableEstimateCount++;
          attendanceTimeSum +=
            (new Date(password.data_hora_finalizacao_atendimento) -
              new Date(password.data_geracao_senha)) /
            60000;
        } else {
          lostPasswords++;
        }
      }
    }
    return {
      amount,
      amountCalled,
      lostPasswords,
      reactivateCount,
      attendanceAvg: parseInt(attendanceTimeSum / availableEstimateCount),
    };
  }, [passwords, counter]);

  const countersData = useMemo(() => {
    const passwordsGroupedByCounter = passwords.reduce((acc, password) => {
      if (!acc[password.guiche_id]) acc[password.guiche_id] = [];

      acc[password.guiche_id].push(password);
      return acc;
    }, {});

    const countersUnavailabilityTime = Object.entries(
      passwordsGroupedByCounter
    ).map(([counterId, counterList]) => {
      const busyTimeMilliseconds = counterList.reduce((acc, password) => {
        if (
          password.data_chamada &&
          (password.horario_encaminhamento ||
            password.data_hora_inicio_atendimento)
        ) {
          const initDate = password.data_chamada;
          const endDate = password?.horario_encaminhamento
            ? password.horario_encaminhamento
            : password.data_hora_inicio_atendimento;
          acc += new Date(endDate).getTime() - new Date(initDate).getTime();
        }
        return acc;
      }, 0);
      let idleTimeMilliseconds = 0;
      const sortedPasswords = counterList.sort(
        (a, b) =>
          new Date(a.data_chamada).getTime() -
          new Date(b.data_chamada).getTime()
      );

      for (let i = 0; i < sortedPasswords.length; i++) {
        let currentPassword = sortedPasswords[i];
        if (
          sortedPasswords[i + 1] &&
          currentPassword?.data_hora_finalizacao_atendimento &&
          sortedPasswords[i + 1]?.data_hora_inicio_atendimento
        ) {
          const diffTime =
            new Date(sortedPasswords[i + 1]?.data_hora_inicio_atendimento) -
            new Date(
              currentPassword.data_hora_finalizacao_atendimento
            ).getTime();
          idleTimeMilliseconds += diffTime;
        }
      }
      const counterName = counterList[0]?.guiche?.nome_guiche;
      return {
        counterId,
        counterName,
        idleTime: parseInt(idleTimeMilliseconds),
        busyTime: parseInt(busyTimeMilliseconds),
      };
    });
    return countersUnavailabilityTime;
  }, [passwords]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minWidth: "350px",
      borderColor: `#333333`,
      boxShadow: "none",
      borderRadius: "10px",
      padding: "5px",
      margin: "5px 0px",

      paddingLeft: "30px",
      fontSize: "1rem",
      lineHeight: "1.5rem",

      "::placeholder": {
        color: "hsl(0, 0%, 50%)",
      },
      ":hover": {
        borderColor: "#0280F8",
      },
      ":active, :focus": {
        borderColor: "#0280F8",
        boxShadow: "0px 0px 6px 0px #0280F8",
      },
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      padding: "0px",
      margin: "0px",
      color: `#0280F8`,
      svg: {
        fill: "#0280F8",
      },
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      padding: "0px",
      margin: "0px",
      display: "none",
    }),
    menu: (provided, state) => ({
      ...provided,
      padding: "5px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
      borderLeft: state.isSelected ? `3px solid #0280F8` : "none",
      color: state.isSelected ? `#0280F8` : "inherit",

      "&:hover, &:active": {
        borderLeft: `3px solid #0280F8`,
        color: `#0280F8`,
        backgroundColor: "white",
      },
    }),
  };

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  return (
    <ContentGlobal>
      <ButtonHeader>
        <LayerTitle>Home</LayerTitle>
        <FilterForm onSubmit={handleSubmit(onSubmit)}>
          <ContentInput>
            {/* <Label>Unidade de atendimento</Label> */}
            <Controller
              control={control}
              name="unit"
              render={({ field: { onChange, value } }) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ marginRight: -30, zIndex: 2 }}>
                    <MdBusiness />
                  </div>
                  <div style={{ width: "100%" }}>
                    <Select
                      value={value}
                      styles={customStyles}
                      placeholder="Selecione uma unidade"
                      onChange={(e) => {
                        clearErrors("unit");
                        setValue("unit", e);
                      }}
                      options={units}
                    />
                  </div>
                </div>
              )}
            />
            {errors.unit && errors.unit?.value?.message ? (
              <MessageError>{errors.unit?.value?.message}</MessageError>
            ) : null}
          </ContentInput>

          <ContentInput>
            <Label>Data inicial</Label>
            <Controller
              control={control}
              name="initialDate"
              render={({ field: { onChange, value } }) => (
                <Input
                  id="initialDate"
                  LeftIcon={<MdCalendarToday size={22} />}
                  valueMax={getDate(today)}
                  valueStep="1"
                  value={value}
                  TextInput="Data"
                  typeInput={"date"}
                  handleChange={(e) => onChange(e)}
                  errorMessage={errors.date?.message}
                />
              )}
            />
          </ContentInput>

          <ContentInput>
            <Label>Data final</Label>
            <Controller
              control={control}
              name="finalDate"
              render={({ field: { onChange, value } }) => (
                <Input
                  id="finalDate"
                  LeftIcon={<MdCalendarToday size={22} />}
                  TextInput="Data"
                  typeInput={"date"}
                  handleChange={(e) => onChange(e)}
                  value={value}
                  valueMax={getDate(today)}
                  valueStep="1"
                  errorMessage={errors.date?.message}
                />
              )}
            />
          </ContentInput>
          <ContentButton>
            <Button text="Atualizar" typeButton={"submit"} />
          </ContentButton>
        </FilterForm>
      </ButtonHeader>
      <LayerButtons>
        {user.isadmin ? (
          <>
            <LinkButtonAdmin>
              <ButtonTitle>{avgData?.amount || 0}</ButtonTitle>
              <ButtonAdminDescribe>Quantidade de senhas</ButtonAdminDescribe>
            </LinkButtonAdmin>
            <LinkButtonAdmin>
              <ButtonTitle>{avgData?.amountCalled || 0}</ButtonTitle>
              <ButtonAdminDescribe>
                Quantidade de senhas chamadas
              </ButtonAdminDescribe>
            </LinkButtonAdmin>
            <LinkButtonAdmin>
              <ButtonTitle>
                {avgData?.attendanceAvg || 0}
                <br />
                min
              </ButtonTitle>
              <ButtonAdminDescribe>
                Tempo médio de atendimento
              </ButtonAdminDescribe>
            </LinkButtonAdmin>
          </>
        ) : (
          <>
            <LinkButtonAdmin
              href={`${link[0]}/totem/${getValues("unit")?.value}`}
              target="_blank"
            >
              <ButtonTitle>Nova senha</ButtonTitle>
              <ButtonAdminDescribe>
                Painel para retirada de senhas
              </ButtonAdminDescribe>
            </LinkButtonAdmin>
            <LinkButtonAdmin
              href={`${link[0]}/senhas/${getValues("unit")?.value}`}
              target="_blank"
            >
              <ButtonTitle>Exibição</ButtonTitle>
              <ButtonAdminDescribe>
                Painel para exibição e chamada de senhas
              </ButtonAdminDescribe>
            </LinkButtonAdmin>
          </>
        )}
      </LayerButtons>
      <LayerButtons>
        <LinkButtonAdmin>
          <ButtonTitle>{avgData?.lostPasswords || 0}</ButtonTitle>
          <ButtonAdminDescribe>
            Quantidade de senhas não finalizadas.
          </ButtonAdminDescribe>
        </LinkButtonAdmin>
        <LinkButtonAdmin>
          <ButtonTitle>{avgData?.reactivateCount || 0}</ButtonTitle>
          <ButtonAdminDescribe>Senhas recuperadas.</ButtonAdminDescribe>
        </LinkButtonAdmin>
      </LayerButtons>

      {user.isadmin && passwords.length > 0 ? (
        <>
          <Header
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            Relatório por senha
            <Select
              placeholder="Selecione um guichê"
              getOptionLabel={(counter) => counter.counterName}
              getOptionValue={(counter) => counter.counterId}
              options={[
                defaultCounterFilter,
                ...countersData.filter((counter) => !!counter.counterName),
              ]}
              value={counter}
              onChange={(counter) => setCounter(counter)}
            />
          </Header>
          <Rows style={{ height: "30%" }}>
            <ListHeader
              itens={[
                { label: "Senha" },
                { label: "Guichê", isBroken: true },
                { label: "Data", isBroken: true },
                { label: "Horário de geração", isBroken: true },
                { label: "Horário de atendimento", isBroken: true },
                { label: "Fim de atendimento", isBroken: true },
                { label: "Tempo de espera", isBroken: true },
                { label: "Tempo na recepção", isBroken: true },
                { label: "Tempo de atendimento", isBroken: true },
                { label: "Tempo de espera (atendimento)", isBroken: true },
                { label: "Tempo de atendimento (coleta)", isBroken: true },
              ]}
            />
            {passwords.map((key) =>
              !counter?.counterId ||
              (counter.counterId && key.guiche_id === counter.counterId) ? (
                <RowItem>
                  <RowInfo>
                    <Item>{key.numero_senha}</Item>
                  </RowInfo>
                  <RowInfo>
                    <Item isBroken>{key?.guiche?.nome_guiche || "-"}</Item>
                  </RowInfo>
                  <RowInfo>
                    <Item isBroken>{formatDate(key.data_geracao_senha)}</Item>
                  </RowInfo>
                  <RowInfo>
                    <Item isBroken>{getTime(key.data_geracao_senha)}</Item>
                  </RowInfo>
                  <RowInfo>
                    <Item isBroken>{getTime(key.data_hora_inicio_atendimento)}</Item>
                  </RowInfo>
                  <RowInfo>
                    <Item isBroken>
                      {getTime(key.data_hora_finalizacao_atendimento)}
                    </Item>
                  </RowInfo>
                  <RowInfo>
                    <Item isBroken>
                      {getDiff(key.data_geracao_senha, key.data_chamada)}
                    </Item>
                  </RowInfo>
                  <RowInfo>
                    <Item isBroken>{calcWaitTimeAtCounter(key)}</Item>
                  </RowInfo>
                  <RowInfo>
                    <Item isBroken>
                      {getDiff(
                        key.data_hora_inicio_atendimento,
                        key.data_hora_finalizacao_atendimento
                      )}
                    </Item>
                  </RowInfo>
                  <RowInfo>
                    <Item isBroken>
                      {getDiff(
                        key.data_hora_inicio_atendimento,
                        key.horario_encaminhamento
                      )}
                    </Item>
                  </RowInfo>
                  <RowInfo>
                    <Item isBroken>
                      {getDiff(
                        key.horario_encaminhamento,
                        key.horario_finalizacao_encaminhamento,
                      )}
                    </Item>
                  </RowInfo>
                </RowItem>
              ) : null
            )}
          </Rows>
          <br />
          <Header>Relatório por guichê</Header>
          <Rows style={{ height: "30%" }}>
            <ListHeader
              itens={[
                { label: "Guichê" },
                { label: "Tempo indisponível" },
                { label: "Tempo ocioso" },
              ]}
            />
            {countersData.map((counter) => (
              <RowItem key={counter.counterId}>
                <RowInfo>
                  <Item>{counter.counterName || "Não identificado"}</Item>
                </RowInfo>
                <RowInfo>
                  <Item>{formattedTime(counter.busyTime)}</Item>
                </RowInfo>
                <RowInfo>
                  <Item>{formattedTime(counter.idleTime)}</Item>
                </RowInfo>
              </RowItem>
            ))}
          </Rows>
        </>
      ) : null}
    </ContentGlobal>
  );
}
