import React from "react";
import { MdKeyboardArrowDown } from "react-icons/md";

import {
  Content,
  ContainerSelect,
  Icon,
  IconSelect,
  MessageError,
  Container,
} from "./styles";

export default function Select({
  children,
  LeftIcon,
  defaultTitle = "",
  value,
  registerValue,
  errorMessage,
  setError,
  handleChange,
  bgColor,
  removeError = false,
  containerStyle = {},
}) {
  const selectProps = handleChange
    ? {
        value,
        onChange: handleOnChangeSelect,
      }
    : registerValue;

  function handleOnChangeSelect(event) {
    handleChange(event.target?.value);
  }

  return (
    <Container style={containerStyle}>
      <Content bgColor={bgColor}>
        {LeftIcon ? <Icon>{LeftIcon}</Icon> : null}
        <ContainerSelect {...selectProps}>
          <option
            value="default"
            id="option-default"
            defaultValue={true}
            onClick={() => {
              setError("Selecione um valor válido");
            }}
          >
            {defaultTitle}
          </option>
          {children}
        </ContainerSelect>

        <IconSelect>
          <MdKeyboardArrowDown />
        </IconSelect>
      </Content>
      {removeError ? null : <MessageError>{errorMessage}</MessageError>}
    </Container>
  );
}
