import React, { useEffect } from "react";
import Cookie from "js-cookie";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Form,
  MessageError,
  ContentInput,
  ContentButton,
} from "../../../styles";

import { FaBuilding, FaEdit } from "react-icons/fa";
import { CgArrowsV } from "react-icons/cg";
import { BiToggleRight } from "react-icons/bi";
import Api from "../../../../../services/apiService";
import Modal from "../../../../../components/Modal";
import Input from "../../../../../components/Forms/Input";
import Button from "../../../../../components/Forms/Button";
import { toast } from "../../../../../utils/toast";
import Select from "react-select";

export default function Fila({
  isUpdateFila,
  isCreateFila,
  setToLoad,
  toLoad,
  closeWindowModal,
  unit,
  priorities,
  // enviroments,
  fila,
}) {

  const schema = yup.object().shape({
    name: yup.string().required("Campo obrigatório"),
    description: yup.string().required("Campo obrigatório"),
    priority: yup.object().shape({
      id: yup.string().required("Campo obrigatório"),
      nome_prioridade: yup.string(),
    }),
    status: yup.object().shape({
      label: yup.string(),
      value: yup.string(),
    }),
  });

  const defaultValues = {
    name: "",
    description: "",
    priority: undefined,
    status: {
      value: "0",
      label: "Ativa",
    },
  };

  const {
    control,
    reset,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  useEffect(() => {
    clearErrors();
    if (isUpdateFila) {
      setValue("name", fila.nome_fila);
      setValue("description", fila.desc_fila);
      setValue("status", {
        value: fila.status,
        label: fila.status === "0" ? "Ativa" : "Inativa",
      });
      setValue(
        "priority",
        fila.prioridade_id
          ? priorities?.find((u) => fila.prioridade_id[0] === u.id)
          : undefined
      );
    } else {
      reset();
    }
  }, [isUpdateFila, fila]);

  async function onSubmitFila(data) {
    try {
      let newData = {
        unidade_id: unit,
        nome_fila: data.name,
        desc_fila: data.description,
        prioridade_id: [data.priority.id],
      };
      await Api.post("/filas", newData);
      toast("success", "Excelente", "Fila criado com sucesso!");
      closeWindowModal();
      setToLoad(true);
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Ocorreu um erro ao criar o fila."
      );
    }
  }

  async function onSubmitFilaUpdate(dataPayload) {
    try {
      const payload = {
        unidade_id: unit,
        nome_fila: dataPayload.name,
        desc_fila: dataPayload.description,
        status: dataPayload.status.value,
        prioridade_id: [dataPayload.priority.id],
      };

      const { data } = await Api.put(`/filas/${fila.id}`, payload);
      toast(
        "success",
        "Sucesso",
        data?.message || "Fila atualizado com sucesso!"
      );
      closeWindowModal();
      setToLoad(true); // TODO: Change reload method
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Não foi possível atualizar o fila"
      );
    }
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: `#333333`,
      backgroundColor: "transparent",
      boxShadow: "none",
      borderRadius: "8px",
      padding: "0.3rem",
      paddingLeft: "30px",
      margin: "5px 0px",

      "::placeholder": {
        color: "hsl(0, 0%, 50%)",
      },
      ":hover": {
        borderColor: "#0280F8",
      },
      ":active, :focus": {
        borderColor: "#0280F8",
        boxShadow: "0px 0px 6px 0px #0280F8",
      },
    }),
    container: (provided, state) => ({
      ...provided,
      zIndex: "auto",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: `#0280F8`,
      svg: {
        fill: "#0280F8",
      },
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    menu: (provided, state) => ({
      ...provided,
      width: "38%",
      position: "fixed",
      top: "auto",
      marginTop: "-5px",
      zIndex: 9999,
      padding: "5px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
      borderLeft: state.isSelected ? `3px solid #0280F8` : "none",
      color: state.isSelected ? `#0280F8` : "inherit",

      "&:hover, &:active": {
        borderLeft: `3px solid #0280F8`,
        color: `#0280F8`,
        backgroundColor: "white",
      },
    }),
  };

  return (
    <Modal
      modalTitle={(isUpdateFila ? "Editar" : "Cadastrar") + " fila"}
      modalActive={isCreateFila}
      modalSetActive={() => closeWindowModal()}
    >
      <Form>
        <ContentInput>
          <Controller
            control={control}
            name="name"
            render={({ field: { onChange, value } }) => (
              <Input
                LeftIcon={<FaBuilding size={22} />}
                width="100"
                height="80"
                TextInput="Nome da fila"
                typeInput={"text"}
                value={value}
                handleChange={onChange}
                errorMessage={errors?.name ? errors?.name.message : null}
              />
            )}
          />
        </ContentInput>
        <ContentInput>
          <Controller
            control={control}
            name="description"
            render={({ field: { onChange, value } }) => (
              <Input
                LeftIcon={<FaEdit size={22} />}
                width="100"
                height="80"
                TextInput="Breve descrição da fila"
                typeInput={"text"}
                value={value}
                handleChange={onChange}
                errorMessage={
                  errors?.description ? errors?.description.message : null
                }
              />
            )}
          />
        </ContentInput>
        <ContentInput>
          {!toLoad && (
            <Controller
              control={control}
              name="priority"
              render={({ field: { onChange, value } }) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CgArrowsV
                    style={{
                      marginLeft: "10px",
                      marginRight: "-32px",
                    }}
                  />
                  <div style={{ width: "100%" }}>
                    <Select
                      value={value}
                      options={priorities}
                      onChange={onChange}
                      styles={customStyles}
                      placeholder="Selecione uma fila/prioridade"
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) => option.nome_prioridade}
                      noOptionsMessage={() => "Prioridade não encontrada"}
                    />
                  </div>
                </div>
              )}
            />
          )}
          {errors.priority && errors.priority?.id?.message ? (
            <MessageError>{errors.priority?.id?.message}</MessageError>
          ) : null}
        </ContentInput>
        <ContentInput>
          {!toLoad && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <BiToggleRight
                style={{
                  marginLeft: "10px",
                  marginRight: "-32px",
                }}
              />
              <div style={{ width: "100%" }}>
                <Controller
                  control={control}
                  name="status"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      id="status"
                      value={value}
                      options={[
                        { value: "0", label: "Ativa" },
                        { value: "1", label: "Inativa" },
                      ]}
                      onChange={(e) => setValue("status", e)}
                      styles={customStyles}
                      placeholder="Status"
                      isDisabled={!isUpdateFila}
                    />
                  )}
                />{" "}
              </div>
            </div>
          )}
          {errors.status?.message ? (
            <MessageError>{errors.status?.message}</MessageError>
          ) : null}
        </ContentInput>
        <ContentButton>
          <Button
            width={30}
            height={100}
            text={isUpdateFila ? "Atualizar" : "Cadastrar"}
            typeButton="button"
            HandleClick={handleSubmit(
              isUpdateFila ? onSubmitFilaUpdate : onSubmitFila
            )}
          />
        </ContentButton>
      </Form>
    </Modal>
  );
}
