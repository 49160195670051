import React from "react";
import image from "../../assets/Logos/Image-pc.svg";

import { Content, Image, Info, Icon, ImagePC } from "./styles";

export default function Unit({
  name = "",
  description = "",
  guiches = "00",
  statusUnit = true,
  shared = false,
  handleClick,
}) {
  return (
    <Content
      isShared={shared}
      onClick={() => (handleClick ? handleClick() : null)}
    >
      <Image>
        <Icon>
          <ImagePC src={image} />
        </Icon>
        {name}
      </Image>
      <Info>{description}</Info>
    </Content>
  );
}
