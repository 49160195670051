import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  display: ${(props) => (props.setDisplay ? "block" : "none")};
`;
export const ContentLogo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const LogoName = styled.img`
  width: 10em;
`;

export const Key = styled.div`
  width: 100%;
  height: 30%;
  text-align: center;
  font-size: 50px;
  line-height: 36px;
  font-weight: 700;
  margin-top: 15px;
`;

export const Unit = styled.div`
  width: 100%;
  height: 10%;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  margin-top: 1.2rem;
`;

export const Attendance = styled.div`
  width: 100%;
  height: 10%;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
`;

export const Datetime = styled.div`
  width: 100%;
  height: 20%;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
`;

export const ContentQrCode = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 10px;
`;
