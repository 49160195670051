import Axios from 'axios'
import { toast } from '../utils/toast';
import store from '../store/storeConfig';
import { logOut } from '../store/Actions/userAction';
import { viewHome } from '../store/Actions/panelAction';
import Cookies from 'js-cookie';
import getBaseUrl from './getBaseUrl';

const BASE_URL = getBaseUrl() + 'api';

const apiService = Axios.create({ baseURL: BASE_URL });

apiService.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${Cookies.get("token")}`;
    return Promise.resolve(config);
  },
  (error) => {
    console.error('Erro na requisição:', error);
    return Promise.reject(error);
  }
);

apiService.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    if (error?.code === 'ERR_NETWORK') {
      throw new Error('Verifique sua conexão');
    }
    if (error?.response?.status === 401) {
      toast('error', 'Erro', 'Sessão expirada!');
      store.dispatch(logOut());
      store.dispatch(viewHome());
      Cookies.remove('user');
      Cookies.remove('token');
      Cookies.remove('userName');
    }
    return Promise.reject(error);
  }
);
export default apiService