import { SET_CLINIC, SET_UNIT, SET_GUICHE, PANEL_OPTIONS_SHOW_ALL } from '../actionTypes';

const initialState = {
  unidade : {},
  guiche  : {},
  showOptions: false,
}

export default function clinicReducer(state = initialState, action){
  switch(action.type){
    case SET_CLINIC:
      return action.payload
    case SET_UNIT:
      return {...state , unidade:action.payload}
    case SET_GUICHE:
      return {...state , guiche:action.payload}
    case PANEL_OPTIONS_SHOW_ALL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}