import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  display: ${(props) => (props.setDisplay !== "none" ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
`;

export const ModalLoading = styled.div`
  width: 30%;
  height: 50%;
`;
