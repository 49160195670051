import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useTheme } from "styled-components";
import {
  ContentGlobal,
  LayerTitle,
  LayerButtons,
  ButtonAdmin,
  ButtonAdminTitle,
  RowAction,
  RowInfo,
  RowItem,
  Item,
  Rows,
  Icons,
  Actions,
  ButtonContainer,
  ButtonHeader,
} from "../../styles";

import { FaEdit } from "react-icons/fa";
import { MdArrowRight } from "react-icons/md";
import { HiOutlineTrash, HiOutlineXCircle } from "react-icons/hi";

import Api from "../../../../services/apiService";
import Layout from "../../../../components/Layout";
import Loading from "../../../../components/Loading";
import CounterModal from "./Modals/Counter";
import EnvironmentModal from "./Modals/Environment";
import FilaModal from "./Modals/Fila";
import KindAttendanceModal from "./Modals/KindAttendance";
import UnitModal from "../Units/Modals/Unit";
import ConfigModal from "./Modals/Config";
import { toast } from "../../../../utils/toast";
import { ListHeader } from "../../../../components/ListHeader";
import { getCount } from "../../../../utils";
import Filter from "../../../../components/Filter";
import Button from "../../../../components/Forms/Button";
import apiService from "../../../../services/apiService";
import Menu from "../../../../components/MenuSenhas";

export default function UpdateUnits() {
  const theme = useTheme();
  const FILTER = { asc: false, term: "", limit: 10, offset: 0 };
  const [typeFilters, setTypeFilters] = useState(FILTER);
  const [counterFilters, setCounterFilters] = useState(FILTER);
  const [environFilters, setEnvironFilters] = useState(FILTER);
  const [filaFilters, setFilaFilters] = useState(FILTER);
  const [typeCount, setTypeCount] = useState(0);
  const [counterCount, setCounterCount] = useState(0);
  const [environCount, setEnvironCount] = useState(0);
  const [filaCount, setFilaCount] = useState(0);
  const params = useParams();
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();

  // Estados dos modais
  const [isCreateTipoAtendimento, setIsCreateTipoAtendimento] = useState("none");
  const [isCreateGuiche, setIsCreateGuiche] = useState("none");
  const [isCreateAmbiente, setIsCreateAmbiente] = useState("none");
  const [isCreateFila, setIsCreateFila] = useState("none");
  const [isUpdateCounter, setIsUpdateCounter] = useState(false);
  const [selectedCounter, setSelectedCounter] = useState();
  const [selectedType, setSelectedType] = useState();
  const [isUpdateEnvironment, setIsUpdateEnvironment] = useState(false);
  const [selectedEnvironment, setSelectedEnvironment] = useState();
  const [isUpdateFila, setIsUpdateFila] = useState(false);
  const [selectedFila, setSelectedFila] = useState();
  const [isUpdateUnit, setUpdateUnit] = useState("none");

  const [unitConfig, setUnitConfig] = useState({});
  const [isCreateConfig, setIsCreateConfig] = useState("none");

  const [unitCurrent, setUnitCurrent] = useState({});
  const [tipoAtendimentos, setTipoAtendimentos] = useState({});
  const [tipoGuiches, setTipoGuiches] = useState({});
  const [queues, setQueues] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [filas, setFilas] = useState([]);
  const [types, setTypes] = useState([]);
  const [counters, setCounters] = useState([]);
  const [environments, setEnvironments] = useState([]);
  const [tokenValidate, setTokenValidate] = useState(false);
  const [toLoad, setToLoad] = useState(true);

  useEffect(() => {
    if (user === undefined || user.token === false) {
      setTokenValidate(false);
    }
    setTokenValidate(true);
  }, [user]);

  function closeWindowModal() {
    setIsCreateGuiche("none");
    setIsCreateAmbiente("none");
    setIsCreateTipoAtendimento("none");
    setIsCreateFila("none");
    setUpdateUnit("none");
    setIsCreateConfig("none");
  }

  async function handleDeleteType(id) {
    try {
      const { data } = await Api.delete(`tipo-atendimentos/${id}`);
      toast(
        "success",
        "Sucesso",
        data?.message || "Tipo de atendimento removido com sucesso!"
      );
      setTypes((_types) => _types.filter((c) => c.id !== id)); 
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Não foi possível remover o tipo de atendimento."
      );
    }
  }

  async function handleDeleteCounter(id) {
    try {
      const { data } = await Api.delete(`guiches/${id}`);
      toast(
        "success",
        "Sucesso",
        data?.message || "Guichê removido com sucesso!"
      );
      setCounters((_counters) => _counters.filter((c) => c.id !== id)); //TODO: Reload page instead
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Não foi possível remover o guichê."
      );
    }
  }

  async function handleDeleteGuicheCompartilhado(id) {
    toast(
      "error",
      "Erro",
      "Não é possível remover o guichê compartilhado."
    );
  }

  async function handleDeleteEnvironment(id) {
    try {
      const { data } = await Api.delete(`ambientes/${id}`);
      toast(
        "success",
        "Sucesso",
        data?.message || "Ambiente removido com sucesso!"
      );
      setEnvironments((environs) => environs.filter((e) => e.id !== id));
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Não foi possível remover o ambiente."
      );
    }
  }

  async function handleDeleteFila(id) {
    try {
      const { data } = await Api.delete(`/filas/${id}`);
      toast("success", "Sucesso", data.message || "Fila removida com sucesso!");
      loadFilas(filaFilters);
      // setFilas((filas) => filas.filter((e) => e.id !== id));
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Não foi possível remover a fila."
      );
    }
  }

  function handleUpdateType(type) {
    setSelectedType(type);
    setIsCreateTipoAtendimento("flex");
  }

  function handleUpdateCounter(counter) {
    setIsUpdateCounter(true);
    setSelectedCounter(counter);
    setIsCreateGuiche("flex");
  }

  function handleUpdateEnvironment(environment) {
    setIsCreateAmbiente("flex");
    setIsUpdateEnvironment(true);
    setSelectedEnvironment(environment);
  }

  function handleUpdateFila(fila) {
    setIsCreateFila("flex");
    setIsUpdateFila(true);
    setSelectedFila(fila);
  }

  function loadBasicData() {
    Promise.all([
      Api.get(`/unidades/${params.id}`,),
      Api.get("/tipo-atendimentos", {  params: { unidade_id: params.id }  }),
      Api.get("/prioridades", { params: { unidade_id: params.id }}),
      Api.get("/tipo-guiches"),
      Api.get("/filas", { params: { unidade_id: params.id }}),
    ])
      .then(
        ([units, attendanceKind, priorities, countersKind, queuesResponse]) => {
          setUnitCurrent(units.data);
          setTipoAtendimentos(attendanceKind.data);
          setPriorities(priorities.data);
          setTipoGuiches(countersKind.data);
          setQueues(queuesResponse.data);
        }
      )
      .catch((errors) => {
        toast(
          "error",
          "Erro",
          "Ocorreu um erro ao carregar essa página. Tente novamente mais tarde."
        );
      })
      .finally(() => {
        setToLoad(false);
      });
    apiService.get(`/unidades/${params.id}/config`)
      .then(({ data }) => {
        setUnitConfig(data);
      })
      .catch(() => {
        console.log('Não foi encontrado as configurações do painel')
      });
  }

  async function loadTypes(filters) {
    try {
      // setRefresh(true);
      const _params = {
        unidade_id: params.id,
        sort: `${filters.asc ? "-" : ""}tipo_atendimento`,
        limit: filters.limit,
        offset: filters.offset,
      };
      if (filters.term) _params.tipo_atendimento = filters.term;
      const { data, headers } = await Api.get("/tipo-atendimentos", {
        params: _params,
      });
      const count = getCount(headers);
      setTypeCount(count);
      setTypes(data);
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Não foi possível buscar os tipos de atendimento"
      );
    }
    // setRefresh(false);
  }
  async function loadCounters(filters) {
    try {
      // setRefresh(true);
      const _params = {
        unidade_id: params.id,
        sort: `${filters.asc ? "-" : ""}nome_guiche`,
        limit: filters.limit,
        offset: filters.offset,
      };
      if (filters.term) _params.nome_guiche = filters.term;
      const { data, headers } = await Api.get("/guiches", {
        params: _params,
      });
      const count = getCount(headers);
      setCounterCount(count);
      setCounters(data);
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Não foi possível buscar os guichês"
      );
    }
    // setRefresh(false);
  }

  async function loadEnvironments(filters) {
    try {
      // setRefresh(true);
      const _params = {
        unidade_id: params.id,
        sort: `${filters.asc ? "-" : ""}nome_ambiente`,
        limit: filters.limit,
        offset: filters.offset,
      };
      if (filters.term) _params.nome_ambiente = filters.term;

      const { data, headers } = await Api.get("/ambientes", {
        params: _params,
      });
      const count = getCount(headers);
      setEnvironments(data);
      setEnvironCount(count);
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Não foi possível buscar os ambientes"
      );
    }
    // setRefresh(false);
  }

  async function loadFilas(filters) {
    try {
      // setRefresh(true);
      const _params = {
        unidade_id: params.id,
        sort: `${filters.asc ? "-" : ""}nome_fila`,
        limit: filters.limit,
        offset: filters.offset,
      };
      if (filters.term) _params.nome_fila = filters.term;

      const { data, headers } = await Api.get("/filas", {
        params: _params,
      });
      const count = getCount(headers);
      setFilas(data);
      setFilaCount(count);
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Não foi possível buscar as filas"
      );
    }
    // setRefresh(false);
  }

  function getStatusLabel(status) {
    if (!status || status === "0") return "Ativo";
    if (status === "1") return "Inativo";
    if (status === "2") return "Excluído";
  }

  useEffect(() => {
    if (toLoad) {
      loadBasicData();
    }
  }, [toLoad]);

  useEffect(() => {
    loadTypes(typeFilters);
  }, [typeFilters]);

  useEffect(() => {
    loadCounters(counterFilters);
  }, [counterFilters]);

  useEffect(() => {
    loadEnvironments(environFilters);
  }, [environFilters]);

  useEffect(() => {
    loadFilas(filaFilters);
  }, [filaFilters]);

  return (
    <Layout token={tokenValidate}>
      {toLoad ? (
        <Loading setDisplay={toLoad} />
      ) : (
        <>
          <CounterModal
            unit={params.id}
            setToLoad={setToLoad}
            counter={selectedCounter}
            isCreateGuiche={isCreateGuiche}
            isUpdateCounter={isUpdateCounter}
            closeWindowModal={closeWindowModal}
            tipoAtendimentos={tipoAtendimentos}
            tipoGuiches={tipoGuiches}
            queues={queues}
          />
          <EnvironmentModal
            unit={params.id}
            setToLoad={setToLoad}
            environment={selectedEnvironment}
            isCreateAmbiente={isCreateAmbiente}
            isUpdateEnvironment={isUpdateEnvironment}
            closeWindowModal={closeWindowModal}
          />
          <KindAttendanceModal
            unit={params.id}
            setToLoad={setToLoad}
            isCreateTipoAtendimento={isCreateTipoAtendimento}
            tipoAtendimento={selectedType}
            closeWindowModal={closeWindowModal}
          />
          <UnitModal
            isCreateUnit={isUpdateUnit}
            setRefresh={setToLoad}
            closeWindowModal={closeWindowModal}
            isUpdate
            unit={unitCurrent}
          />
          <FilaModal
            unit={params.id}
            setToLoad={setToLoad}
            toLoad={toLoad}
            fila={selectedFila}
            environments={environments}
            priorities={priorities}
            isCreateFila={isCreateFila}
            isUpdateFila={isUpdateFila}
            closeWindowModal={closeWindowModal}
          />
          <ConfigModal
            unit={params.id}
            setToLoad={setToLoad}
            toLoad={toLoad}
            closeWindowModal={closeWindowModal}
            showModal={isCreateConfig}
            isUpdate={!!unitConfig?.id}
            unitConfig={unitConfig}
          />
          <ContentGlobal>
            <ButtonHeader>
              <ButtonContainer style={{ minWidth: 74 }}>
                <Button text="<" HandleClick={() => navigate(`/admin`)} />
              </ButtonContainer>
              <LayerTitle style={{ alignSelf: "center" }}>
                {unitCurrent.unidade ? unitCurrent.unidade : ""}
              </LayerTitle>
              <ButtonContainer>
                <Button
                  text="Configurar painel de senhas"
                  HandleClick={() => {
                    setIsCreateConfig("flex")
                  }}
                />
                <Button
                  text="Atualizar informações"
                  HandleClick={() => setUpdateUnit("flex")}
                />
              </ButtonContainer>
            </ButtonHeader>
            <LayerTitle>Telas</LayerTitle>
            <LayerButtons>
              <Menu 
                unidade={
                  {
                    id: params.id,
                    token: unitConfig?.token
                  } 
                }
              />
              <ButtonAdmin onClick={() => navigate(`/totem/${params.id}`)}>
                <ButtonAdminTitle>Totem</ButtonAdminTitle>
                <MdArrowRight color={theme.colors.white} />
              </ButtonAdmin>
            </LayerButtons>
            <Filter
              title="Tipos de atendimento"
              searchProps={{
                searchableItens: [{ label: "nome" }],
                handleSearch: (term) =>
                  setTypeFilters((old) => ({ ...old, term })),
              }}
              paginationProps={{
                count: typeCount,
                handlePageChange: (limit, offset) =>
                  setTypeFilters((old) => ({ ...old, limit, offset })),
              }}
              actions={
                <Actions>
                  <Button
                    text="Cadastrar"
                    HandleClick={() => {
                      setIsCreateTipoAtendimento("flex");
                    }}
                  />
                </Actions>
              }
            >
              <Rows>
                <ListHeader
                  itens={[
                    {
                      label: "Nome",
                      asc: typeFilters.asc,
                      canOrder: true,
                      order: "tipo_atendimento",
                    },
                    { label: "Status" },
                    { label: "Ações", isAction: true },
                  ]}
                  handleOrder={() =>
                    setTypeFilters((old) => ({ ...old, asc: !old.asc }))
                  }
                />
                {Array.isArray(types) ? types.map((type, index) => (
                  <RowItem key={index.toString()}>
                    <RowInfo>
                      <Item>{type.tipo_atendimento}</Item>
                    </RowInfo>
                    <RowInfo>
                      <Item>{getStatusLabel(type.status)}</Item>
                    </RowInfo>
                    <RowAction>
                      <Icons onClick={() => handleUpdateType(type)}>
                        <FaEdit />
                      </Icons>
                      <Icons onClick={() => handleDeleteType(type.id)}>
                        <HiOutlineTrash color={theme.colors.warning} />
                      </Icons>
                    </RowAction>
                  </RowItem>
                )) : null}
              </Rows>
            </Filter>

            <Filter
              title="Filas"
              searchProps={{
                itens: [{ label: "nome" }],
                handleSearch: (value) =>
                  setFilaFilters((old) => ({ ...old, term: value })),
              }}
              paginationProps={{
                count: filaCount,
                handlePageChange: (limit, offset) =>
                  setFilaFilters((old) => ({ ...old, limit, offset })),
              }}
              actions={
                <Actions>
                  <Button
                    text="Cadastrar"
                    HandleClick={() => {
                      setIsCreateFila("flex");
                      setIsUpdateFila(false);
                    }}
                  />
                </Actions>
              }
            >
              <Rows>
                <ListHeader
                  itens={[
                    {
                      label: "Nome",
                      asc: filaFilters.asc,
                      canOrder: true,
                      order: "nome_fila",
                    },
                    { label: "Status" },
                    { label: "Ações", isAction: true },
                  ]}
                  handleOrder={() =>
                    setFilaFilters((old) => ({ ...old, asc: !old.asc }))
                  }
                />
                {filas.map((fila, index) => (
                  <RowItem key={index.toString()}>
                    <RowInfo>
                      <Item>{fila.nome_fila}</Item>
                    </RowInfo>
                    <RowInfo>
                      <Item>{getStatusLabel(fila.status)}</Item>
                    </RowInfo>
                    <RowAction>
                      <Icons onClick={() => handleUpdateFila(fila)}>
                        <FaEdit />
                      </Icons>
                      <Icons onClick={() => handleDeleteFila(fila.id)}>
                        <HiOutlineTrash color={theme.colors.warning} />
                      </Icons>
                    </RowAction>
                  </RowItem>
                ))}
              </Rows>
            </Filter>

            <Filter
              title="Ambientes de Coleta"
              searchProps={{
                searchableItens: [{ label: "nome" }],
                handleSearch: (term) =>
                  setEnvironFilters((old) => ({ ...old, term })),
              }}
              paginationProps={{
                count: environCount,
                handlePageChange: (limit, offset) =>
                  setEnvironFilters((old) => ({ ...old, limit, offset })),
              }}
              actions={
                <Actions>
                  <Button
                    text="Cadastrar"
                    HandleClick={() => {
                      setIsCreateAmbiente("flex");
                      setIsUpdateEnvironment(false);
                    }}
                  />
                </Actions>
              }
            >
              <Rows>
                <ListHeader
                  itens={[
                    {
                      label: "Nome",
                      asc: environFilters.asc,
                      canOrder: true,
                      order: "nome_ambiente",
                    },
                    { label: "Status" },
                    { label: "Ações", isAction: true },
                  ]}
                  handleOrder={() =>
                    setEnvironFilters((old) => ({ ...old, asc: !old.asc }))
                  }
                />
                {environments.map((environment, index) => (
                  <RowItem key={index.toString()}>
                    <RowInfo>
                      <Item>{environment.nome_ambiente}</Item>
                    </RowInfo>
                    <RowInfo>
                      <Item>{getStatusLabel(environment.status)}</Item>
                    </RowInfo>
                    <RowAction>
                      <Icons
                        onClick={() => handleUpdateEnvironment(environment)}
                      >
                        <FaEdit />
                      </Icons>
                      <Icons
                        onClick={() => handleDeleteEnvironment(environment.id)}
                      >
                        <HiOutlineTrash color={theme.colors.warning} />
                      </Icons>
                    </RowAction>
                  </RowItem>
                ))}
              </Rows>
            </Filter>




            <Filter
              title="Guichês"
              searchProps={{
                searchableItens: [{ label: "nome" }],
                handleSearch: (term) =>
                  setCounterFilters((old) => ({ ...old, term })),
              }}
              paginationProps={{
                count: counterCount,
                handlePageChange: (limit, offset) =>
                  setCounterFilters((old) => ({ ...old, limit, offset })),
              }}
              actions={
                <Actions>
                  <Button
                    text="Cadastrar"
                    HandleClick={() => {
                      setIsCreateGuiche("flex");
                      setIsUpdateCounter(false);
                    }}
                  />
                </Actions>
              }
            >
              <Rows>
                <ListHeader
                  itens={[
                    {
                      label: "Nome",
                      asc: counterFilters.asc,
                      canOrder: true,
                      order: "nome_guiche",
                    },
                    { label: "Status" },
                    { label: "Ações", isAction: true },
                  ]}
                  handleOrder={() =>
                    setCounterFilters((old) => ({ ...old, asc: !old.asc }))
                  }
                />
                {counters.map((counter, index) => (
                  <RowItem key={index.toString()}>
                    <RowInfo>
                      <Item>{counter.nome_guiche}</Item>
                    </RowInfo>
                    <RowInfo>
                      <Item>{getStatusLabel(counter.status)}</Item>
                    </RowInfo>
                    <RowAction>
                      <Icons onClick={() => handleUpdateCounter(counter)}>
                        <FaEdit />
                      </Icons>
                      {counter.uso_compartilhado 
                        ? (                          
                          <Icons onClick={() => handleDeleteGuicheCompartilhado(counter.id)}>
                            <HiOutlineXCircle color={theme.colors.warning} />
                          </Icons>
                        )
                        : (
                          <Icons onClick={() => handleDeleteCounter(counter.id)}>
                            <HiOutlineTrash color={theme.colors.warning} />
                          </Icons>
                        )}
                    </RowAction>
                  </RowItem>
                ))}
              </Rows>
            </Filter>
            
          
          </ContentGlobal>
        </>
      )}
    </Layout>
  );
}
