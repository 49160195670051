import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Button from "../../components/Forms/Button";

import Cookie from "js-cookie";

import {
  Content,
  Container,
  FormLogin,
  ContentInput,
  ContentButton,
  Label,
  Body,
  Header,
  Title,
  ButtonContent,
} from "./styles";

import Layout from "../../components/Layout";
import Api from "../../services/apiService";
import Input from "../../components/Forms/Input";
import Select from "react-select";
import { toast } from "../../utils/toast";
import { BiCalendar, BiFile, BiUserCircle } from "react-icons/bi";
import { formatCpf } from "../../utils/format";
import ToPrint from "../../components/ToPrint";
import { useReactToPrint } from "react-to-print";
import { MdBusiness } from "react-icons/md";

export default function InfoESenha() {
  const navigate = useNavigate();
  const params = useParams();
  let componentRef = useRef(null);
  const [eSenha, setESenha] = useState();
  const [key, setKey] = useState({});
  const [activePrint, setActivePrint] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    Api.get(`/senhas/${params.id}`)
      .then((response) => {
        setESenha(response.data);
      })
      .catch((err) => {
        toast(
          "error",
          "Erro",
          err?.response?.data?.message ||
            "Não foi possível buscar as informações"
        );
      });
  }, []);

  async function onSubmit(data) {
    if (data === undefined) {
      return null;
    }
    let newData = {
      nome_cliente_agendamento: eSenha.nome_cliente_agendamento,
      documento_cliente_agendamento: eSenha.documento_cliente_agendamento,
      data_agendamento: eSenha.data_agendamento,
      unidade_id: eSenha.unidade_id,
    };

    try {
      toast("info", "Aguarde!", "Estamos gerando sua senha");
      await Api.put(`/senhas/e-senha/${eSenha.id}`, newData)
        .then((response) => {
          setKey(response.data);
          setActivePrint(true);
          handlePrint();
          toast(
            "success",
            "Imprimindo!!!",
            "Retire sua senha e aguarde na recepção "
          );
          setActivePrint(false);
          navigate(`/totem/${eSenha.unidade_id}`);
        })
        .catch((error) => {
          toast(
            "error",
            "Erro",
            error?.response?.data?.message ||
              "Não foi possível validar a senha."
          );
        });
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Não foi possível validar a senha."
      );
    }
  }

  const cookie = Cookie.get("token");

  useEffect(() => {
    if (!Cookie.get("token")) {
      navigate("/");
    }
  }, [cookie, navigate]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: `#333333`,
      backgroundColor: "transparent",
      boxShadow: "none",
      borderRadius: "8px",
      padding: "0.3rem",
      paddingLeft: "30px",
      margin: "5px 0px",

      "::placeholder": {
        color: "hsl(0, 0%, 50%)",
      },
      ":hover": {
        borderColor: "#0280F8",
      },
      ":active, :focus": {
        borderColor: "#0280F8",
        boxShadow: "0px 0px 6px 0px #0280F8",
      },
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: `#0280F8`,
      svg: {
        fill: "#0280F8",
      },
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    menu: (provided, state) => ({
      ...provided,
      padding: "5px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
      borderLeft: state.isSelected ? `3px solid #0280F8` : "none",
      color: state.isSelected ? `#0280F8` : "inherit",

      "&:hover, &:active": {
        borderLeft: `3px solid #0280F8`,
        color: `#0280F8`,
        backgroundColor: "white",
      },
    }),
  };

  const { control, handleSubmit } = useForm();

  return (
    <Layout hideProfile hideAside attendance>
      <Content>
        <Header>
          <ButtonContent className="voltar">
            <Button
              className="voltar"
              text="<"
              HandleClick={() =>
                navigate(`/validar-senha/${eSenha && eSenha.unidade_id}`)
              }
            />
          </ButtonContent>
          <Title>Validação de Senha</Title>
          <ButtonContent></ButtonContent>
        </Header>
        <Body>
          <Container>
            <FormLogin onSubmit={handleSubmit(onSubmit)}>
              <ContentInput>
                <Label>
                  Unidade de atendimento <span style={{ color: "red" }}>*</span>
                </Label>
                <Controller
                  control={control}
                  name="unit"
                  render={(field) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <MdBusiness
                        style={{
                          marginLeft: "10px",
                          marginRight: "-32px",
                        }}
                      />
                      <div style={{ width: "100%" }}>
                        <Select
                          {...field}
                          id="unit"
                          options={
                            eSenha && [
                              {
                                value: eSenha?.unidade_id,
                                label: eSenha?.nome_unidade,
                              },
                            ]
                          }
                          value={
                            eSenha && {
                              value: eSenha.unidade_id,
                              label: eSenha.nome_unidade,
                            }
                          }
                          styles={customStyles}
                          placeholder="Selecione uma unidade"
                          noOptionsMessage={() => "Unidade não encontrada"}
                          isDisabled
                        />
                      </div>
                    </div>
                  )}
                />
              </ContentInput>
              <ContentInput>
                <Label>
                  Nome <span style={{ color: "red" }}>*</span>
                </Label>
                <Controller
                  control={control}
                  name="name"
                  render={(field) => (
                    <Input
                      {...field}
                      LeftIcon={<BiUserCircle size={22} />}
                      width="100"
                      height="20"
                      disabled
                      TextInput="Nome"
                      typeInput={"text"}
                      value={eSenha && eSenha?.nome_cliente_agendamento}
                    />
                  )}
                />
              </ContentInput>
              <ContentInput>
                <Label>
                  Documento <span style={{ color: "red" }}>*</span>
                </Label>
                <Controller
                  control={control}
                  name="document"
                  render={(field) => (
                    <Input
                      {...field}
                      LeftIcon={<BiFile size={22} />}
                      width="100"
                      height="20"
                      disabled
                      TextInput="CPF"
                      typeInput={"text"}
                      value={
                        eSenha &&
                        formatCpf(eSenha?.documento_cliente_agendamento)
                      }
                    />
                  )}
                />
              </ContentInput>
              <ContentInput>
                <Label>
                  Data de agendamento <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  name="date"
                  LeftIcon={<BiCalendar size={22} />}
                  width="100"
                  height="20"
                  disabled
                  TextInput="Data"
                  typeInput={"date"}
                  value={eSenha && eSenha?.data_agendamento.substring(0, 10)}
                />
              </ContentInput>
              <ContentButton>
                <Button text="Validar Senha" typeButton={"submit"} />
              </ContentButton>
            </FormLogin>
          </Container>
        </Body>
        <ToPrint
          referency={componentRef}
          active={activePrint}
          setKey={key}
          unitName={eSenha?.nome_unidade}
        />
      </Content>
    </Layout>
  );
}
