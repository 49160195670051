import React from "react";
import { QRCodeCanvas } from "qrcode.react";

import {
  Content,
  LogoName,
  Datetime,
  Key,
  Unit,
  ContentQrCode,
  ContentLogo,
  Datetime2,
  Attendance,
} from "./styles";

import SoftlabLogo from "../../assets/Logos/GroupLogoPass.svg";

export default function ToPrint({
  referency,
  active,
  setKey,
  typeAttendance,
  unitName,
}) {
  function dateConverter(date) {
    const data = new Date(date);
    const newDate = `${
      data.getDate() < 10 ? "0" + data.getDate() : data.getDate()
    }/${
      data.getMonth() < 10 ? "0" + (data.getMonth() + 1) : data.getMonth() + 1
    }/${data.getFullYear()}`;
    const newHour = `${
      data.getHours() < 10 ? "0" + data.getHours() : data.getHours()
    }:${data.getMinutes() < 10 ? "0" + data.getMinutes() : data.getMinutes()}`;
    return `${newDate} - ${newHour}`;
  }

  function dateConverterSchedule(date) {
    const data = new Date(date);
    const newDate = `${
      data.getDate() < 10 ? "0" + data.getDate() : data.getDate()
    }/${
      data.getMonth() < 10 ? "0" + (data.getMonth() + 1) : data.getMonth() + 1
    }/${data.getFullYear()}`;
    return `${newDate}`;
  }

  return (
    <Content ref={referency} setDisplay={active}>
      <ContentLogo>
        <LogoName src={SoftlabLogo} />
      </ContentLogo>
      <Unit>{unitName}</Unit>
      <Attendance>Tipo de atendimento: E-Senha</Attendance>
      <Datetime>
        Data de agendamento - {dateConverterSchedule(setKey.data_agendamento)}
      </Datetime>
      <Datetime2>{dateConverter(setKey.createdAt)}</Datetime2>
      <ContentQrCode>
        <QRCodeCanvas value={setKey.id} />
      </ContentQrCode>
      <Key>{setKey.codigo}</Key>
    </Content>
  );
}
