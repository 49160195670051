import { START_CRONOMETER, END_CRONOMETER } from '../actionTypes';

const initialState = {
  isStart: false
}

export default function cronometerReducer(state = initialState, action){
  switch(action.type){
    case START_CRONOMETER:
      return{
        ...state,
        isStart: action.payload
      }
    case END_CRONOMETER:
      return{
        ...state,
        isStart: action.payload
      }
    default:
      return state;
  }
}