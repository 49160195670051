import React, { useEffect, useMemo, useState } from 'react';
import { MdLockOpen, MdPersonOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Swal } from 'sweetalert2';
import Cookies from "js-cookie";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import jwt_decode from "jwt-decode";

import {
  openLoadingModal,
  closeLoadingModal,
} from "../../store/Actions/loadingAction";
import { unitUser } from "../../store/Actions/unitAction";
import { userLogIn } from "../../store/Actions/userAction";

import Api from "../../services/apiService";
import LogoLogin from "../../assets/Logos/GroupLogo.svg";
import frontEndVersion from '../../../package.json';

// Styles from Login
import {
  Content,
  ContainerLogin,
  ContentVersion,
  FormLogin,
  ContentInput,
  ContentLogo,
  ContentRememberPass,
  ContentButton,
  Anchor,
  Label,
} from "./styles";

//Components form application
import Button from "../../components/Forms/Button";
import Input from "../../components/Forms/Input";
import { toast } from "../../utils/toast";
import { MANAGER, SUPERVISOR } from "../../utils/constants";
import getBaseUrl from '../../services/getBaseUrl';


const schema = yup
.object({
  username: yup.string().required("O campo e-mail é obrigatório"),
  password: yup.string().required("O campo senha é obrigatório"),
})
.required();

export default function Login() {
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const BASE_URL = getBaseUrl() + 'api';

  const [backEndVersion, setBackEndVersion] = useState();

  const versaoFront = useMemo(
    () =>
      frontEndVersion.versaoFront.majorVersion
        ? `${frontEndVersion.versaoFront.majorVersion}.${frontEndVersion.versaoFront.minorVersion}.${frontEndVersion.versaoFront.build}.${frontEndVersion.versaoFront.release}`
        : '',
    [frontEndVersion]
  );

  const versaoBack = useMemo(
    () =>
    backEndVersion || '',
    [backEndVersion]
  );

  function validateBackAndFront(back) {
    const backShortVersion = `${back.data.majorVersion}.${back.data.minorVersion}`;

    const frontShortVersion = `${frontEndVersion.versaoFront.majorVersion}.${frontEndVersion.versaoFront.minorVersion}`;
    
    if (backShortVersion !== frontShortVersion) {
      throw new Error(
        `Versão de FrontEnd(${frontShortVersion}) e BackEnd(${backShortVersion}) incompatíveis entre si`
      );
    }
  }
  async function getBackAndFrontVersion() {
    try {
      const back = await Api.post(BASE_URL + "/sessao/version")
      validateBackAndFront(back)
      const backShortVersion = `${back.data.majorVersion}.${back.data.minorVersion}.${back.data.build}.${back.data.release}`;
      setBackEndVersion(backShortVersion)
    } catch (error) {
      throw error;
    }
  }

  useEffect(() => {
    getVersion();
  }, []);

  function getVersion() {
    try {
      getBackAndFrontVersion();
    } catch (error) {
      Swal.fire({
        text: error.message,
        icon: 'error',
        allowOutsideClick: false,
        heightAuto: false,
        showConfirmButton: false,
      });
    }
  }

  function onSubmit(data) {
    if (data === undefined) {
      return null;
    }
    let newData = {
      username: data.username,
      password: data.password,
    };
    dispatch(openLoadingModal());
    
    Api.post(BASE_URL + "/sessao", newData)
      .then((response) => {
        if (response.data.user.token) {
          let decoded = jwt_decode(response.data.user.token);
          const diferenca = decoded.exp - decoded.iat;
          const diferencaEmDias = Math.floor(diferenca / (60 * 60 * 24));
          Cookies.set(
            "user",
            JSON.stringify({
              ...response.data.user,
              unidades: response.data.unidades,
            }),
            { sameSite: "strict" }
          );
          Cookies.set("token", response.data.user.token, { expires: diferencaEmDias });
          Cookies.set("userName", response.data.user.username, {
            sameSite: "strict",
          });
          dispatch(userLogIn(response.data));
          if (
            response.data.user.isadmin ||
            response.data.user.perfil[0] === SUPERVISOR ||
            response.data.user.perfil[0] === MANAGER
          ) {
            navigate("/admin");
            dispatch(closeLoadingModal());
          } else {
            navigate("/unidade");
            dispatch(unitUser(response.data));
            dispatch(closeLoadingModal());
          }
        } else {
          dispatch(closeLoadingModal());
        }
      })
      .catch((err) => {
        toast(
          "error",
          "Erro",
          err?.response?.data?.message || "Não foi possível realizar login",
        );
        dispatch(closeLoadingModal());
      });
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <Content>
      <ContainerLogin>
        <FormLogin onSubmit={handleSubmit(onSubmit)}>
          <ContentLogo>
            <img src={LogoLogin} alt="Logo Softlab" />
          </ContentLogo>
          <ContentInput>
            <Label>
              Usuário<span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              LeftIcon={<MdPersonOutline />}
              TextInput="Digite seu usuário"
              typeInput={"text"}
              HandleKey={(key) => (key === "Enter" ? onSubmit() : null)}
              registerValue={{ ...register("username") }}
              errorMessage={errors.username?.message}
            />
          </ContentInput>
          <ContentInput>
            <Label>
              Senha<span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              LeftIcon={<MdLockOpen />}
              TextInput="***********"
              typeInput={"password"}
              HandleKey={(key) => (key === "Enter" ? onSubmit() : null)}
              registerValue={{ ...register("password") }}
              errorMessage={errors.password?.message}
            />
          </ContentInput>
          <ContentRememberPass>
            <Anchor to="/esqueceu">Esqueci a minha senha</Anchor>
          </ContentRememberPass>
          <ContentButton>
            <Button text="Entrar" typeButton={"submit"} />
          </ContentButton>
          <ContentVersion>
            {' '}
            <span >
              <strong>
                &copy;
                {new Date().getFullYear()}
              </strong>{' '}
              SOFTLAB LTDA.{' '}
              <small>
                (FrontEnd:
                {versaoFront}, BackEnd:
                {versaoBack})
              </small>
          </span>
          </ContentVersion>
          
        </FormLogin>
      </ContainerLogin>
    </Content>
  );
}
