import Swal from "sweetalert2";

function toast(type, title, text) {
  if(!Swal.isVisible()){
    Swal.fire({
      position: "top-end",
      icon: type,
      title,
      text,
      showConfirmButton: false,
      timer: type === 'error' ? 2000 : 1500,
      toast: true,
    })
  }
}

export { toast };