import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import io from "socket.io-client";
import Cookie from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Content, Progress, Container, PanelAttendence, Body, Header } from "./styles";
import Layout from "../../components/Layout";
import RevalidatePassword from "../../components/RevalidatePassword";
import AttendanceScreen from "../../components/AttendanceScreen";
import ButtonInfo from "../../components/Forms/ButtonInfo";
import ProgressBar from "../../components/ProgressBar";
import Api from "../../services/apiService";
import { setClinicData, setPanelShowOptions } from "../../store/Actions/clinicAction";
import { endCronometer, startCronometer } from "../../store/Actions/cronometerAction";
import { toast } from "../../utils/toast";
import { setItem, getItem } from "../../utils/localstorage";
import { ButtonContainer, LayerTitle } from "../Admin/styles";
import Button from "../../components/Forms/Button";
import getBaseUrl from "../../services/getBaseUrl";

let socket;

export default function Attendance() {
  global.inactiveTime = 1;
  const INACTIVE_TIME_LIMIT = 5;
  const MINUTE_IN_MILLISECONDS = 60000;
  const params = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { isStart } = useSelector((state) => state.cronometer);
  const clinic = useSelector((state) => state.clinic);

  const [startAttendence, setStartAttendence] = useState(false);
  const [stageLevel, setStageLevel] = useState(0);
  const [key, setKey] = useState("");
  const [lastsKeys, _setLastsKeys] = useState([]);
  const [idAtendimento, setIdAtendimento] = useState(0);
  const lastKeysRef = useRef(lastsKeys);

  function setLastsKeys(keys) {
    _setLastsKeys(keys);
    lastKeysRef.current = keys;
  }

  function setupEventHandler() {
    socket.on("chamarEmGuiche", (data) => {
      if (data.guiche_id === params.counterId) {
        if (!startAttendence) {
          setKey(data.senha);
          setStartAttendence(true);
        }
      }
    });
    socket.on("updateAndReadPasswords", (data) => {
      const currentKeys = lastKeysRef?.current || [];
      const calledKeys = data?.filter(
        (k) =>
          !!k.data_hora_inicio_atendimento && k?.guiche?.id === params.counterId
      );
      const unShownKeys = calledKeys.filter(
        (ck) => !currentKeys.some((curK) => curK.id === ck.id)
      );
      const latestKeys = [...currentKeys, ...unShownKeys].sort(
        (a, b) =>
          b.data_hora_inicio_atendimento - a.data_hora_inicio_atendimento
      );
      setLastsKeys(latestKeys);
    });
  }

  async function getNextPasswords(limit = 10) {
    try {
      const { data } = await Api.get("/senhas", {
        params: {
          unidade_id: params.id,
          status: 0,
          sort: "-data_geracao_senha",
          limit,
        },
      });
      return data;
    } catch (error) {
      return [];
    }
  }

  function resetInactiveTime() {
    global.inactiveTime = 0;
  }

  async function autoCallPassword() {
    if (global.inactiveTime === INACTIVE_TIME_LIMIT) {
      const nextPasswords = await getNextPasswords(1);
      if (Array.isArray(nextPasswords) && nextPasswords.length > 0) {
        Swal.fire({
          title: "Chamada automática",
          text: "Chamar próxima senha",
          showDenyButton: true,
          confirmButtonText: "Chamar",
          denyButtonText: "Recusar",
          confirmButtonColor: "#0280F8",
          timer: 30000,
        }).then((result) => {
          if (result.isConfirmed) {
            chamarProximaSenha();
          }
          if (result.isDenied) {
            toast("info", "Atenção", "Senha postergada");
          }
        });
        resetInactiveTime();
      }
    } else {
      global.inactiveTime = global.inactiveTime + 1;
    }
  }

  useEffect(() => {
    socket = io(getBaseUrl(),{
      query: {
        unidade_id: params?.id,
      }
    });
    if (socket) {
      setupEventHandler();
      return () => {
        socket.disconnect();
      }
    }
  }, []);

  useEffect(() => {
    dispatch(setPanelShowOptions(true));
    if (!clinic.guiche.nome_guiche || !clinic.unidade.unidade) {
      loadClinic();
    }

    const interval = setInterval(autoCallPassword, MINUTE_IN_MILLISECONDS);

    const lastPasswords =
      getItem(`@SoftLab:LastPasswords:${params.counterId}`) || "[]";
    setLastsKeys(JSON.parse(lastPasswords));

    return () => {
      clearInterval(interval);
      dispatch(endCronometer());
      dispatch(setPanelShowOptions(false));
    };
  }, []);

  async function loadClinic() {
    try {
      const { data: unidade } = await Api.get(`unidades/${params.id}`);
      const { data: guiche } = await Api.get(`guiches/${params.counterId}`);
      dispatch(setClinicData({ unidade, guiche, showOptions: true }));
    } catch (error) {}
  }

  async function chamarProximaSenha() {
    resetInactiveTime();
    try {
      const _params = {
        guiche_id: params.counterId,
        guiche: true,
      };
      const { data } = await Api.get(`/senhas/${params.id}/senha`, {
        params: _params,
      });
      setKey(data);
      dispatch(startCronometer());
      setStageLevel(1);
    } catch (error) {
      toast(
        "info",
        "Sem atendimento",
        error?.response?.data?.message || "Não existe mais nenhuma senha na fila!"
      );
    }
  }

  useEffect(() => {
    // if(lastsKeys.length >= 2) {
    //   let lastTwo = [lastsKeys[0], lastsKeys[1]];
    //   if(lastTwo[0].prioridade_id === null || lastTwo[1].prioridade_id === null) {
    //     setCallPriority(true)
    //   } else {
    //     setCallPriority(false)
    //   }
    // } else {
    //   setCallPriority(true)
    // }
    saveLastPasswords(lastsKeys);
  }, [lastsKeys]);

  function saveLastPasswords(lastsKeys) {
    setItem(
      `@SoftLab:LastPasswords:${params.counterId}`,
      JSON.stringify(lastsKeys)
    );
  }

  async function iniciarAtendimento() {
    resetInactiveTime();
    try {
      dispatch(endCronometer());
      setStageLevel(2);
      const _config = { params: undefined };
      const { data } = await Api.post(
        "/atendimentos",
        {
          guiche_id: clinic.guiche.id,
          senha_id: key.id,
          unidade_id: clinic.unidade.id,
        },
        _config
      );
      setStartAttendence(true);
      setIdAtendimento(data.id);
      socket.emit("atendimentoIniciado",clinic?.unidade?.id);
    } catch(error) {
      toast(
        'error',
        'Erro',
        error?.response?.data?.message || 'Não foi possível iniciar o atendimento',
      );
    }
  }

  async function encaminharSenha(event) {
    const _config = { params: undefined };
    try {
      await Api.post(
        "/encaminhamentos",
        {
          senha_id: key.id,
          guiche_id: clinic.guiche.id,
          atendimento_id: idAtendimento,
          unidade_id: clinic.unidade.id,
        },
        _config
      );
      socket.emit("encaminharSenha");
      toast(
        "success",
        "Encaminhamento",
        "Atendimento encaminhado com sucesso!"
      );
      setStageLevel(0);
      let value = [...lastsKeys];
      value.unshift(key);
      setStartAttendence(event.attendance);
      setLastsKeys(value);
      dispatch(endCronometer());
      setIdAtendimento(0);
      setKey("");
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message ||
          "Houve uma falha no armazenamento do atendimento"
      );
    }
    resetInactiveTime();
  }

  async function encerrarAtendimento(event) {
    resetInactiveTime();
    try {
      const _config = { params: undefined };
      await Api.patch(`/atendimentos/${idAtendimento}`, {}, _config);
      let value = [...lastsKeys];
      value.unshift(key);
      setLastsKeys(value);
      setKey("");
      setStartAttendence(event.attendance);
      dispatch(endCronometer());
      setStageLevel(3); // TODO: Remove
      socket.emit("atendimentoFinalizado", clinic?.unidade?.id);
      toast("info", "Ótimo!", "Atendimento encerrado com sucesso!");
      setStageLevel(0);
      setIdAtendimento(0);
    } catch(error) {
      toast(
        'error',
        'Erro',
        error?.response?.data?.message || 'Não foi possível encerrar o atendimento',
      );
    }
  }

  async function chamarMesmaSenha() {
    resetInactiveTime();
    try {
      const { data } = await Api.get(`/senhas/chamar-novamente/${key.id}/${params.counterId}`, {
      });
      setKey(data);
      dispatch(startCronometer());
      setStageLevel(1);
    } catch (error) {
      toast(
        "info",
        "Limite de chamadas",
        error?.response?.data?.message || "Esta senha excedeu o número de chamadas"
      );
    }
  }

  function handleProfile() {
    navigate("/profile");
  }

  return (
    <Layout attendance showAllAttendance handleProfile={handleProfile}>
      <Content>
        <Header>
          <ButtonContainer style={{ minWidth: 74 }}>
            <Button text="<" HandleClick={() => navigate(`/admin`)} />
          </ButtonContainer>
          <LayerTitle style={{ textAlign: "center" }}>
            {clinic && clinic?.unidade?.unidade}
            {", "}
            {clinic && clinic?.guiche?.nome_guiche}
          </LayerTitle>
          <ButtonContainer style={{ minWidth: 74 }}></ButtonContainer>
        </Header>
        <Body>
          <Container>
            <Progress>
              <ProgressBar stage={stageLevel} />
            </Progress>
            <PanelAttendence>
              {startAttendence ? (
                <>
                  <ButtonInfo
                    title="Chamar"
                    describe="Chamar senha no seu guichê"
                    handleClick={() => null}
                    isDisabled={true}
                  />
                  <ButtonInfo
                    title="Chamar novamente"
                    describe="Chamar novamente em seu guichê a mesma senha"
                    handleClick={() => null}
                    isDisabled={true}
                  />
                  <ButtonInfo
                    title="Iniciar"
                    describe="Iniciar o atendimento"
                    handleClick={() => null}
                    isDisabled={true}
                  />
                </>
              ) : (
                <>
                  {!isStart ? (
                    <ButtonInfo
                      title="Chamar"
                      describe="Chamar senha no seu guichê"
                      handleClick={() => chamarProximaSenha()}
                    />
                  ) : (
                    <ButtonInfo
                      title="Chamar"
                      describe="Chamar senha no seu guichê"
                      handleClick={() => null}
                      isDisabled={true}
                    />
                  )}
                  {key !== "" ? (
                    <>
                      {!isStart ? (
                        <ButtonInfo
                          title="Chamar novamente"
                          describe="Chamar novamente em seu guichê a mesma senha"
                          handleClick={() => chamarMesmaSenha()}
                        />
                      ) : (
                        <ButtonInfo
                          title="Chamar novamente"
                          describe="Chamar novamente em seu guichê a mesma senha"
                          handleClick={() => null}
                          isDisabled={true}
                        />
                      )}
                      <ButtonInfo
                        title="Iniciar"
                        describe="Iniciar o atendimento"
                        handleClick={() => iniciarAtendimento()}
                      />
                    </>
                  ) : (
                    <>
                      <ButtonInfo
                        title="Chamar novamente"
                        describe="Chamar novamente em seu guichê a mesma senha"
                        handleClick={() => null}
                        isDisabled={true}
                      />
                      <ButtonInfo
                        title="Iniciar"
                        describe="Iniciar o atendimento"
                        handleClick={() => null}
                        isDisabled={true}
                      />
                    </>
                  )}
                </>
              )}
            </PanelAttendence>
            <RevalidatePassword unitId={params.id} />
          </Container>
          <AttendanceScreen
            setPassword={key}
            attendanceActive={startAttendence}
            lasts={lastsKeys}
            closeAttendance={(e) => encerrarAtendimento(e)}
            encaminhar={(e) => encaminharSenha(e)}
            unitAndGuiche={clinic}
          />
        </Body>
      </Content>
    </Layout>
  );
}
