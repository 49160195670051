import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Form } from "../Keys/styles";
import jwt_decode from "jwt-decode";

import { MdLockOpen, MdPersonOutline } from "react-icons/md";
import {
  openLoadingModal,
  closeLoadingModal,
} from "../../store/Actions/loadingAction";
import { unitUser } from "../../store/Actions/unitAction";
import { userLogIn } from "../../store/Actions/userAction";
import { logOut } from "../../store/Actions/userAction";

import Api from "../../services/apiService";
import Modal from "../../components/Modal";
import Input from "../../components/Forms/Input";
import Button from "../../components/Forms/Button";
import { toast } from "../../utils/toast";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const schema = yup.object().shape({
  username: yup.string().required("O campo login é obrigatório."),
  password: yup.string().required("O campo senha é obrigatório."),
});


export default function RefreshLoginModal({
  showLoginModal,
  closeWindowModal,
  pegarUnidade
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const defaultValues = {
    username: "",
    password: "",
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  async function onSubmit(form) {
    try {
      const newData = {
        username: form.username,
        password: form.password,
      };
      dispatch(openLoadingModal());
      const {data} = await Api.post("/sessao", newData);
      if (typeof data?.user?.token !== 'string') {
        throw new Error('Token inválido!')
      }
        let decoded = jwt_decode(data.user.token);
        const diferenca = decoded.exp - decoded.iat;
        const diferencaEmDias = Math.floor(diferenca / (60 * 60 * 24));
        Cookies.set(
          "user",
          JSON.stringify({
            ...data.user,
            unidades: data.unidades,
          }),
          { sameSite: "strict" }
        );
        Cookies.set("token", data.user.token, { expires: diferencaEmDias });
        Cookies.set("userName", data.user.username, {
          sameSite: "strict",
        });
        dispatch(userLogIn(data));
        if (data && data.user) {
          dispatch(unitUser(data));
          dispatch(closeLoadingModal());
        } else {
          dispatch(closeLoadingModal());
          toast(
            "error",
            "Erro",
            "Usuário não encontrado",
            );
        }
        await pegarUnidade(data.user.token)
    } catch (err) {
      toast(
        "error",
        "Erro",
        err?.response?.data?.message || "Não foi possível realizar login",
        );
    } finally {
      closeWindowModal()
      dispatch(closeLoadingModal());
    }
  }

  useEffect(() => {
    if (!showLoginModal) reset()
  }, [showLoginModal, reset])

  return (
    <Modal
      modalWidth={50}
      modalHeight={60}
      modalTitle={"Fazer login"}
      modalActive={showLoginModal}
      modalSetActive={() => {
        closeWindowModal()
        Cookies.remove("user");
        Cookies.remove("token");
        Cookies.remove("userName");
        dispatch(logOut());
        // dispatch(viewHome()); // Reset first page on next login
        navigate("/");
      }}
    >
      <Form>
        <Controller
          control={control}
          name="username"
          render={({ field: { onChange, value } }) => (
            <Input
              width="100"
              height="20"
              LeftIcon={<MdPersonOutline />}
              TextInput="Digite seu usuário"
              typeInput={"text"}
              handleChange={onChange}
              errorMessage={errors.username?.message}
              value={value}
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          render={({ field: { onChange, value } }) => (
            <Input
              width="100"
              height="20"
              LeftIcon={<MdLockOpen />}
              TextInput="***********"
              typeInput={"password"}
              handleChange={onChange}
              errorMessage={errors.password?.message}
              value={value}
            />
          )}
        />
        <Button
          width={30}
          height={25}
          text={"Enviar"}
          typeButton="button"
          HandleClick={handleSubmit(onSubmit)}
        />
      </Form>
    </Modal>
  );
}
