import MenuContainer from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ButtonAdmin, ButtonAdminTitle } from '../../pages/Admin/styles';
import { MdArrowRight,MdArrowDropDown } from 'react-icons/md';
import { useTheme } from 'styled-components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function MenuSenhas(props) {
  const unidade = props?.unidade
  const titulosTiposPaineis = {
    1 : "Senhas Recepção",
    2 : "Senhas Coleta",
    3 : "Todas as senhas"
  }
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  async function setTipoPainel(tipoPainel){
    navigateToPainel(tipoPainel)
    handleClose()
  }
  
  async function navigateToPainel(tipoPainel = 3) {    
    let route = `/senhas/${unidade.id}/${tipoPainel}`;
    if (unidade.token) {
      route = route + `/?token=${unidade.token}`;
    }
    navigate(route)
  }

  return (
    <>
      <ButtonAdmin
        onClick={handleClick} 
        id="fade-icon"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}> 
        <ButtonAdminTitle
        >Selecione o painel de senhas desejado
        </ButtonAdminTitle>
        {open ? <MdArrowDropDown color={theme.colors.white}/> : <MdArrowRight color={theme.colors.white}/>}
      </ButtonAdmin>
      <MenuContainer
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => setTipoPainel("1")}>{titulosTiposPaineis[1]}</MenuItem>
        <MenuItem onClick={() => setTipoPainel("2")}>{titulosTiposPaineis[2]}</MenuItem>
        <MenuItem onClick={() => setTipoPainel("3")}>{titulosTiposPaineis[3]}</MenuItem>
      </MenuContainer>
    </>
  );
}