import { OPEN_LOADING,CLOSE_LOADING } from '../actionTypes';

export function openLoadingModal(){
  return{
    type: OPEN_LOADING,
    payload: 'flex'
  }
}

export function closeLoadingModal(){
  return{
    type: CLOSE_LOADING,
    payload: 'none'
  }
}