import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsGear } from "react-icons/bs";
import { BiLogOut, BiUser } from "react-icons/bi";
import Cookie from "js-cookie";
import { useDispatch, useSelector } from "react-redux";

import SoftlabLogo from "../../assets/Logos/LogoHeader.svg";

import {
  Content,
  Logo,
  ImageProfile,
  Profile,
  DropMenu,
  DropButton,
  IconMenu,
  HelloText,
} from "./styles";
import { logOut } from "../../store/Actions/userAction";
import { viewHome } from "../../store/Actions/panelAction";

export default function Header({ handleProfile, hideProfile }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [isActive, setIsActive] = useState(false);

  function Logout() {
    Cookie.remove("user");
    Cookie.remove("token");
    Cookie.remove("userName");
    dispatch(logOut());
    dispatch(viewHome()); // Reset first page on next login
    navigate("/");
  }

  return (
    <Content>
      <Logo src={SoftlabLogo} hasMargin={hideProfile} />
      <Profile
        hideProfile={hideProfile}
        isActive={isActive}
        onClick={() => setIsActive(!isActive)}
        onMouseLeave={() => setIsActive(false)}
      >
        {hideProfile ? null : (
          <>
            <ImageProfile>
              <BiUser size={30} />
            </ImageProfile>
            <HelloText isActive={isActive}>Olá,&nbsp;</HelloText>
            {user.username}
            <DropMenu
              setDisplay={isActive}
              onMouseDown={() => setIsActive(true)}
            >
              <DropButton onClick={handleProfile}>
                Perfil <BsGear color="#fff" />{" "}
              </DropButton>
              <DropButton onClick={() => Logout()}>
                Sair <BiLogOut color="#fff" />{" "}
              </DropButton>
            </DropMenu>
          </>
        )}
      </Profile>
      <IconMenu></IconMenu>
    </Content>
  );
}
