function getCount(headers) {
  const _infos = headers['content-range'].split('/');
  const _count = _infos[_infos.length - 1].split('-')[1];
  return _count;
}

function getDate(date) {
  date = new Date(date);
  date = date.toJSON();
  date = date.split('T', 1)[0];
  return date;
}

function addDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

function sortPasswords(passwords = []) {
  function getDateDiff(firstDate, lastDate) {
    const dateA = firstDate.data_agendamento || firstDate.data_geracao_senha;
    const dateB = lastDate.data_agendamento || lastDate.data_geracao_senha;
    return dateA - dateB;
  }

  const normalPasswords = [];
  const prioritiesPasswords = [];

  for (const password of passwords) {
    if (password?.prioridade?.id) prioritiesPasswords.push(password);
    else normalPasswords.push(password);
  }

  prioritiesPasswords.sort((a, b) => {
    const diff = a.prioridade.ordem - b.prioridade.ordem;
    if (diff === 0) return getDateDiff(a, b);
    return diff;
  });

  normalPasswords.sort((a, b) => getDateDiff(a, b));

  return {
    prioritiesPasswords,
    normalPasswords,
  };
}

export { getCount, getDate, addDays, sortPasswords };