import styled, {keyframes} from 'styled-components'

const LoadingAnimation = keyframes`
  from{width: 0%;}
  to{width: 100%;}
`

export const Content = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-self: center;
  padding: 10px 5%;
  margin: 0 auto;
`;

export const Area = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ isLast }) => isLast ? 'flex-end' : 'flex-start'};
  flex: ${({ isLast }) => isLast ? 1 : 9};
`;

export const ContainerCircle = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  text-align: center;
  white-space: nowrap;
`;

export const LineProgress = styled.div`
  width: ${props => props.stepActive ? '100%' : '0%' };
  height: 8px;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 20px;
  animation: ${props => props.stepActive === true ? LoadingAnimation : 'none' };
  animation-duration: 2s;
  margin: -20px -5% 0;
`;

export const Circle = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${props => props.stepActive ? props.theme.colors.primary : props.theme.colors.button };
  border-radius: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #fff;
`

export const Title = styled.div`
  margin-top: 10px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;