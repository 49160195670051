import styled, { keyframes } from "styled-components";

const formAnimate = keyframes`
  0% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

export const ContentGrid = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.white};
  margin: 15px 0;
`;

export const ContentKeys = styled.div`
  flex-direction: column;
  flex: 1;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;

  > div {
    /* Replace children customization */
    margin: 15px 0;
  }
`;

export const ContainerGrid = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  flex: 1;
`;

export const KeysContainer = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.white};
  max-height: calc(((100vh - 90px) / 2) - 30px);
`;

export const LogsContainer = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const ContentEnvironment = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const ContentModalEnvironment = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 5%;
`;

export const ContentKey = styled.div`
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
`;

export const LayerKey = styled.div`
  width: 30%;
  height: 80%;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 10px;
  font-size: 70px;
  color: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentButtons = styled.div`
  width: 100%;
  height: 70%;
`;

export const LayerModalEnvironment = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
`;

export const DataEnvironment = styled.div`
  width: 100%;
  height: 80%;
  padding: 0 40px;
  display: flex;
  position: relative;
`;

export const TitlesList = styled.div`
  width: 40%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 10px 20px 20px 10px;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 20px;
  color: ${(props) => props.theme.colors.white};
  > p {
    width: 100%;
    text-align: center;
  }
`;
export const DataList = styled.div`
  width: 60%;
  height: 100%;
  border: 1px solid ${(props) => props.theme.colors.primary};
  border-radius: 0px 20px 20px 0px;
  position: absolute;
  left: 30%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 20px;
  color: ${(props) => props.theme.colors.secondary};
  > p {
    width: 100%;
    padding-left: 20%;
    text-align: center;
  }
`;

export const LayerModalEnvironmentButtons = styled.div`
  margin: 0 15px;
`;

export const KeyButtons = styled.div`
  width: 100%;
  height: 90%;
  border-radius: 0 0 20px 20px;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
`;

export const ContentEnvironmentTitle = styled.span`
  background-color: ${(props) => props.theme.colors.white};
  font-size: 24px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.primary};
  padding: 20px 0;
  text-align: center;
  flex: 1;
`;

export const ContentKeysTitle = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.primary};
  margin: 15px 0;
`;

export const EnvironmentMural = styled.div`
  overflow-y: auto;
  max-height: 70vh !important;
`;

export const LayerButtons = styled.div`
  width: 100%;
  height: 20%;
  align-items: center;
  padding: 20px;
`;
export const ButtonPanel = styled.div`
  background-color: ${(props) =>
    props.toTerminate
      ? props.theme.colors.warning
      : props.theme.colors.primary};
  border-radius: 16px;
  border: none;
  display: flex;
  transition: 0.3s;
  cursor: pointer;
  flex-direction: column;
  padding: 16px;
  margin-bottom: ${({ toTerminate }) => (toTerminate ? 16 : 0)}px;

  :hover {
    transform: scale(1.02);
  }
`;

export const ButtonPanelTitle = styled.span`
  font-size: 26px;
  color: ${(props) => props.theme.colors.white};
`;

export const ButtonPanelDescribe = styled.span`
  font-size: 18px;
  color: ${(props) => props.theme.colors.white};
`;

export const LayerTitle = styled.div`
  width: 100%;
  height: 10%;
`;

export const ContainerKeys = styled.div`
  width: 100%;
  height: 70%;
  padding: 20px 20px 0 20px;
`;

export const KeysMural = styled.div`
  overflow-x: auto;
  display: flex;
`;

export const ContentKeysMural = styled.div`
  overflow: auto;
  flex: 1;
  height: calc(((100vh - 90px) / 2) - 90px);
  padding: 5px 0;
  display: grid;
  grid-template-columns: auto auto auto;
`;

export const ContentLogsMural = styled(ContentKeysMural)`
  display: flex;
  flex-direction: column;
`;

export const ContainerKeysMural = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 20px 20px 0 0;
`;

export const ContainerTitle = styled.div`
  width: 100%;
  min-height: 15%;
  font-size: 24px;
  color: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const PanelAttendence = styled.div`
  width: 55%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  > button {
    margin-bottom: 30px;
  }
`;

export const Environments = styled.div`
  height: 40px;
  margin: 20px 30px;
  display: flex;
  align-items: center;
  font-size: 18px;
  transition: 0.2s;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.border};
  border-radius: 16px;
`;

export const EnvironmentStatus = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${(props) =>
    props.setStatus === false
      ? props.theme.colors.success
      : props.theme.colors.warning};
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  margin-right: 30px;
`;

export const StatusLayer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
  margin-left: 30px;

  > p {
    margin-right: 30px;
  }
`;

export const Logs = styled.div`
  width: 10%;
  height: 80%;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  color: ${(props) => props.theme.colors.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  cursor: pointer;
  :hover {
    background-color: ${(props) => props.theme.colors.secondary};
    color: ${(props) => props.theme.colors.white};
  }
`;

export const LogsModal = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: ${(props) => (props.setDisplay === false ? "none" : "flex")};
  flex-direction: column;
  z-index: 2;
  background-color: ${(props) => props.theme.colors.white};
  animation: ${formAnimate} 2s;
`;

export const MessageLog = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  font-size: 18px;
  width: 100%;
  height: 40px;
  background-color: ${(props) => props.theme.colors.white};
  margin-bottom: 10px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 10px 10px 0 0;
  color: ${(props) => props.theme.colors.white};
  font-weight: 500;
  font-size: 18px;
`;

export const TitleKeys = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.white};
  font-weight: 500;
  font-size: 18px;
`;

export const EnvironmentsContainer = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 10px;
`;

export const KeyGuiche = styled.div`
  min-width: 10%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.secondary};
  margin-right: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: 0.1s;
  :hover {
    opacity: 0.8;
    transform: translateY(4px);
  }
`;

export const Environment = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 15%;
  background-color: ${(props) =>
    props.isDisable !== 0
      ? props.theme.colors.warning
      : props.theme.colors.secondary};
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s;
  :hover {
    transform: translateX(4px);
  }
  font-size: 20px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.white};
`;

export const Icon = styled.div`
  width: 10%;
  height: 50%;
  margin-right: 20px;
`;

export const LogContainer = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin: 4px 0;
  display: flex;
  flex-direction: row;
`;

export const KeyTitle = styled.span`
  font-size: 20px;
  font-weight: 700;
  height: 48px;
  flex: 1;
  background-color: ${(props) => props.theme.colors.secondary};
  color: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EnvorimentTitle = styled.span`
  font-size: 20px;
  font-weight: 700;
  height: 48px;
  flex: 2;
  background-color: ${(props) => props.theme.colors.secondary};
  color: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BusyCaptionEnvironment = styled.div`
  width: 25px;
  height: 25px;
  background-color: ${({ theme }) => theme.colors.warning};
  margin-right: 5px;
  border-radius: 8px;
`;

export const AvailableCaptionEnvironment = styled(BusyCaptionEnvironment)`
  background-color: ${({ theme }) => theme.colors.success};
`;
