import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import Cookies from "js-cookie";
import { FaBuilding, FaEdit } from "react-icons/fa";
import { TiSortNumerically } from "react-icons/ti";
import { FiMap, FiMapPin } from "react-icons/fi";
import { GrIntegration } from "react-icons/gr";
import { useParams } from "react-router-dom";

import { Form } from "../../../styles";
import Input from "../../../../../components/Forms/Input";
import Button from "../../../../../components/Forms/Button";
import Api from "../../../../../services/apiService";
import Modal from "../../../../../components/Modal";
import { toast } from "../../../../../utils/toast";
import {
  formatCep,
  formatPhone,
  sanitizeNumber,
} from "../../../../../utils/format";
import { MdOutlinePhone } from "react-icons/md";
import { BiWorld } from "react-icons/bi";

export default function UnitModal({
  isCreateUnit,
  closeWindowModal,
  setRefresh,
  isUpdate = false,
  unit = null,
}) {

  const params = useParams();

  const schema = yup.object().shape({
    name: yup.string().when("$isUpdate", {
      is: false,
      then: (item) => item.required("O campo nome é obrigatório"),
    }),
    description: yup.string().required("O campo descrição é obrigatório"),
    phone: yup.string().required("O campo telefone é obrigatório"),
    street: yup.string().required("O campo rua é obrigatório"),
    district: yup.string().required("O campo bairro é obrigatório"),
    city: yup.string().required("O campo cidade é obrigatório"),
    uf: yup.string().required("O campo UF é obrigatório"),
    street_2: yup.string(),
    zipCode: yup.string().required("O campo CEP é obrigatório"),
    number: yup.string().required("O campo numero é obrigatório"),
    integrationCode: yup.string().nullable(true),
  });

  const defaultValues = {
    name: "",
    description: "",
    phone: "",
    number: "",
    zipCode: "",
    street: "",
    district: "",
    city: "",
    uf: "",
    street_2: "",
    integrationCode: 0,
  };

  const {
    watch,
    reset,
    control,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    context: { isUpdate },
  });

  function setupUnitData() {
    setValue("name", unit.unidade);
    setValue("description", unit.desc_unidade);
    setValue("phone", formatPhone(unit.telefone || ""));
    setValue("zipCode", formatCep(unit.cep || ""));
    setValue("street", unit.logradouro);
    setValue("number", unit.numero_unidade);
    setValue("district", unit.bairro_unidade);
    setValue("uf", unit.uf);
    setValue("city", unit.cidade);
    setValue("integrationCode", unit.cod_unidade_integrada);
  }

  async function onSubmit(data) {
    try {
      if(!/^\d*$/.test(`${data.integrationCode}`)){
        return toast(
          "error",
          "Erro",
          "Formato de Código de integração da Unidade não permitido"
        )
      }
      let newData = {
        unidade: data.name,
        desc_unidade: data.description,
        cep: sanitizeNumber(data.zipCode),
        telefone: sanitizeNumber(data.phone),
        logradouro: data.street,
        bairro_unidade: data.district,
        uf: data.uf,
        cidade: data.city,
        numero_unidade: data.number,
        complemento_unidade: data.street_2,
        cod_unidade_integrada: data.integrationCode === "" ? null : data.integrationCode,
      };

      await Api.post("/unidades", newData);
      toast("success", "Excelente", "Unidade criada com sucesso!");
      reset();
      closeWindowModal();
      setRefresh(true);
    } catch (error) {
      // console.error(error);
      toast(
        "error",
        "Erro",
        "Não foi possível criar a unidade."
      );
    }
  }

  async function onUpdateSubmit(payload) {
    try {
      if(!/^\d*$/.test(`${payload.integrationCode}`)){
        return toast(
          "error",
          "Erro",
          "Formato de Código de integração da Unidade não permitido"
        )
      }
      const { data } = await Api.put(
        `/unidades/${params.id}`,
        {
          desc_unidade: payload.description,
          cep: sanitizeNumber(payload.zipCode),
          telefone: sanitizeNumber(payload.phone),
          logradouro: payload.street,
          bairro_unidade: payload.district,
          uf: payload.uf,
          cidade: payload.city,
          numero_unidade: payload.number,
          complemento_unidade: payload.street_2,
          cod_unidade_integrada: payload.integrationCode === "" ? null : payload.integrationCode,
        },
      );
      toast(
        "success",
        "Sucesso",
        data?.message || "Unidade alterada com sucesso!"
      );
      closeWindowModal();
    } catch (error) {
      toast(
        "error",
        "Erro",
        "Não foi possível atualizar a unidade"
      );
    }
  }

  useEffect(() => {
    if (unit) {
      setupUnitData();
    }
  }, []);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "zipCode" && value.zipCode?.length === 9)
        viaCep(sanitizeNumber(value.zipCode));
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  async function viaCep(cep) {
    try {
      const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
      setValue("street", data.logradouro);
      setValue("district", data.bairro);
      setValue("city", data.localidade);
      setValue("uf", data.uf);
    } catch (error) {
      toast("warning", "Atenção", "CEP não encontrado!");
    }
  }

  return (
    <Modal
      modalWidth={50}
      modalTitle={`${isUpdate ? "Editar" : "Cadastrar"} Unidade`}
      modalActive={isCreateUnit}
      modalSetActive={() => {
        closeWindowModal();
        clearErrors();
      }}
      style={{ overflow: "hidden" }}
    >
      <Form
        context={{ isUpdate }}
        onSubmit={handleSubmit(isUpdate ? onUpdateSubmit : onSubmit)}
        style={{ overflow: "hidden" }}
      >
        <Controller
          control={control}
          name="name"
          render={({ field: { onChange, value } }) => (
            <Input
              LeftIcon={<FaBuilding size={22} />}
              width="100"
              height="10"
              TextInput="Nome da unidade"
              typeInput={"text"}
              value={value}
              handleChange={onChange}
              errorMessage={errors.name?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="description"
          render={({ field: { onChange, value } }) => (
            <Input
              LeftIcon={<FaEdit size={22} />}
              width="100"
              height="10"
              TextInput="Descrição da unidade"
              typeInput={"text"}
              value={value}
              handleChange={onChange}
              errorMessage={errors.description?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="integrationCode"
          render={({ field: { onChange, value } }) => (
            <Input
              LeftIcon={
                <GrIntegration id="integration" size={22} stroke={"#0280f8"} />
              }
              width="100"
              height="10"
              TextInput="Código de integração da Unidade"
              typeInput={"number"}
              value={value}
              valueMin={1}
              handleChange={onChange}
              errorMessage={errors.integrationCode?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="phone"
          render={({ field: { onChange, value } }) => (
            <Input
              LeftIcon={<MdOutlinePhone size={22} />}
              width="100"
              height="10"
              TextInput="Contato da Unidade"
              typeInput={"text"}
              valueMax={14}
              value={value}
              handleChange={(text) => onChange(formatPhone(text))}
              errorMessage={errors.phone?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="zipCode"
          render={({ field: { onChange, value } }) => (
            <Input
              LeftIcon={<BiWorld size={22} />}
              width="100"
              height="10"
              TextInput="CEP"
              typeInput={"text"}
              handleChange={(text) => onChange(formatCep(text))}
              value={value}
              errorMessage={errors.zipCode?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="street"
          render={({ field: { onChange, value } }) => (
            <Input
              LeftIcon={<FiMap size={22} />}
              width="100"
              height="10"
              TextInput="Logradouro"
              typeInput={"text"}
              value={value}
              handleChange={onChange}
              errorMessage={errors.street?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="number"
          render={({ field: { onChange, value } }) => (
            <Input
              LeftIcon={<TiSortNumerically size={22} />}
              width="100"
              height="10"
              TextInput="Número"
              errorMessage={errors.number?.message}
              value={value}
              handleChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="street_2"
          render={({ field: { onChange, value } }) => (
            <Input
              LeftIcon={<FiMapPin size={22} />}
              width="100"
              height="10"
              TextInput="Complemento"
              typeInput={"text"}
              value={value}
              handleChange={onChange}
              errorMessage={errors.street_2?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="district"
          render={({ field: { onChange, value } }) => (
            <Input
              LeftIcon={<FiMapPin size={22} />}
              width="100"
              height="10"
              TextInput="Bairro"
              typeInput={"text"}
              value={value}
              handleChange={onChange}
              errorMessage={errors.district?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="city"
          render={({ field: { onChange, value } }) => (
            <Input
              LeftIcon={<FiMapPin size={22} />}
              width="100"
              height="10"
              TextInput="Cidade"
              typeInput={"text"}
              value={value}
              handleChange={onChange}
              errorMessage={errors.city?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="uf"
          render={({ field: { onChange, value } }) => (
            <Input
              LeftIcon={<FiMap size={22} />}
              width="100"
              height="10"
              TextInput="UF"
              typeInput={"text"}
              value={value}
              handleChange={onChange}
              errorMessage={errors.uf?.message}
            />
          )}
        />
        <Button
          width={30}
          height={10}
          text={isUpdate ? "Atualizar" : "Cadastrar"}
          typeButton="submit"
        />
      </Form>
    </Modal>
  );
}
