import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import Api from "../../services/apiService";
import _ from "lodash";
import Cookie from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { HiIdentification } from "react-icons/hi";
import { Content, ContentGrid, ButtonPanel, ButtonPanelTitle, ButtonPanelDescribe, KeysMural, ContentEnvironment, ContentEnvironmentTitle, EnvironmentMural, EnvironmentStatus,
  Environments, ContentModalEnvironment, LayerModalEnvironmentButtons, StatusLayer, ContentKeys, ContentKeysTitle, ContentKeysMural, LogContainer, KeyTitle, EnvorimentTitle,
  KeysContainer, LogsContainer, ContentLogsMural, BusyCaptionEnvironment, AvailableCaptionEnvironment } from "./styles";
import { setPanelShowOptions, setUnitData } from "../../store/Actions/clinicAction";
import { closeLoadingModal, openLoadingModal } from "../../store/Actions/loadingAction";
import Layout from "../../components/Layout";
import Modal from "../../components/Modal";
import Key from "../../components/Key";
import { toast } from "../../utils/toast";
import { getDate } from "../../utils";
import Input from "../../components/Forms/Input";
import { Form } from "../Admin/styles";
import Button from "../../components/Forms/Button";
import getBaseUrl from "../../services/getBaseUrl";

let socket;

export default function SharedAttendance() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { unidade } = useSelector((state) => state.clinic);
  const defaultValues = { attendance: "" };
  const schema = yup.object().shape({
    attendance: yup.string().required("Por favor, informe o código do crachá"),
  });

  const {
    control,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  const [senhasEncaminhadas, setSenhasEncaminhadas] = useState([]);
  const [isOpenEnvironment, setIsOpenEnvironment] = useState("none");
  const [isOpenBarCodeScanner, setIsOpenBarCodeScanner] = useState("none");
  const [ambientesDisponiveis, setAmbientesDisponiveis] = useState([]);
  // const [isOpen, setIsOpen] = useState(false)
  const [updateEffect, setUpdateEffect] = useState(true);
  const [history, setHistory] = useState([]);
  const [handleClickEnvironment, setHandleClickEnvironment] = useState({});
  const [activeKey, setActiveKey] = useState({ index: "", data: "" });
  const [referral, setReferral] = useState();

  function filterByUnit(key) {
    return key.unidade_id === params.id;
  }

  function keyUnitDateFilter(key) {
    return (
       filterByUnit(key) &&
      getDate(
        key?.horario_finalizacao_encaminhamento
        || key?.data_agendamento
        || key?.data_geracao_senha
      ) === getDate(new Date())
    );
  }

  useEffect(() => {
    dispatch(setPanelShowOptions(true));
    socket = io(getBaseUrl(),{
      query: {
        unidade_id: unidade,
      }
    });

    socket.on("encaminhamentos", (data) => {
      setSenhasEncaminhadas(_.filter(data, filterByUnit));
    });
    socket.on("encaminhamentosRealizados", (data) => {
      setHistory(_.filter(data, keyUnitDateFilter));
    });
    socket.on("listarAmbientes", (data) => {
      setAmbientesDisponiveis(
        data.filter((env) => env.unidade_id === params.id)
      );
    });

    return () => {
      dispatch(setPanelShowOptions(false));
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (updateEffect) {
      socket.emit("atualizarEncaminhamentos");
      setUpdateEffect(false);
    }
  }, [updateEffect]);

  useEffect(() => {
    if (params.id === "undefined" || params.id === "null") {
      navigate("/unidade");
    } else {
      if (!unidade?.id) loadClinic();
    }

    Api.get(`/unidade-ambientes/${params.id}`).then((res) =>
      setAmbientesDisponiveis(res.data.ambientes)
    );
  }, []);

  async function loadClinic() {
    try {
      dispatch(openLoadingModal());
      const { data } = await Api.get(`/unidades/${params.id}`);
      dispatch(setUnitData(data));
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Não foi possível localizar a unidade"
      );
    }
    dispatch(closeLoadingModal());
  }

  function closeWindowModal() {
    setIsOpenEnvironment("none");
    setIsOpenBarCodeScanner("none");
    reset();
    clearErrors();
  }

  function encaminharAmbiente(data) {
    Api.put(
      "/encaminhamentos",
      {
        senha_id: data.key.id,
        ambiente_id: data.envoriment.id,
        atendente: data.badge,
      }
    )
      .then(() => {
        socket.emit("finalizarEncaminhamento");
        Api.get(`/unidade-ambientes/${params.id}`).then((res) =>
          setAmbientesDisponiveis(res.data.ambientes)
        );
        setUpdateEffect(true);
        closeWindowModal();
        setReferral(undefined);
        reset();
        clearErrors();
        return setActiveKey({ index: "", data: "" });
      })
      .catch((error) => {
        toast(
          "error",
          "Erro",
          error?.response?.data?.message ||
            "Houve um problema no encaminhamento!"
        );
      });
  }

  function openDataEnvironment(environment) {
    const data = {
      envoriment: environment,
      key: activeKey.data,
    };
    if (activeKey.index === "") {
      if (environment.ocupado === false) {
        toast("warning", "Atenção", "Selecione uma senha antes de encaminhar");
      } else {
        setIsOpenEnvironment("flex");
        setHandleClickEnvironment(environment);
      }
    } else {
      if (environment.ocupado === false) {
        setIsOpenBarCodeScanner("flex");
        setReferral(data);
      } else {
        toast("error", "Erro", "Ambiente já se encontra ocupado");
        setActiveKey({ index: "", data: "" });
      }
    }
  }

  function encerrarAtendimento(environment) {
    Api.put(`/ambientes/liberar-ambiente/${environment.id}`, {}).then(
      () => {
        setUpdateEffect(true);
        setIsOpenEnvironment("none");
        toast(
          "success",
          "Sucesso",
          `O Ambiente ${environment.nome_ambiente} foi liberado!`
        );
      }
    );
  }

  function chamarNovamente(environment) {
    const keys = history.filter((h) => h.ambiente_id === environment.id);
    if (keys.length > 0) {
      const first = _.first(keys);
      socket.emit("chamarNovamente", first);
    }
  }

  function activeKeyList(index, currentKey) {
    const key = {
      index: index,
      data: currentKey,
    };
    if (activeKey.index === index) {
      setActiveKey({ index: "", data: "" });
    } else {
      setActiveKey(key);
    }
  }

  function orderByName(a, b) {
    if (a.nome_ambiente < b.nome_ambiente) return -1;
    if (a.nome_ambiente > b.nome_ambiente) return 1;
    return 0;
  }

  function handleProfile() {
    navigate("/profile");
  }

  function onSubmitForm(data) {
    encaminharAmbiente({ ...referral, badge: data.attendance });
  }

  return (
    <Layout attendance handleProfile={handleProfile}>
      <Content>
        <Modal
          modalWidth={40}
          modalHeight={50}
          modalTitle="Verificar atendente"
          modalActive={isOpenBarCodeScanner}
          modalSetActive={() => closeWindowModal()}
        >
          <Form onSubmit={handleSubmit(onSubmitForm)}>
            <Controller
              control={control}
              name="attendance"
              render={({ field: { onChange, value } }) => (
                <Input
                  style={{ flex: 1 }}
                  label="Leia ou digite seu crachá"
                  LeftIcon={<HiIdentification size={22} />}
                  TextInput="Código do crachá"
                  handleChange={onChange}
                  value={value}
                  autofocus={true}
                  errorMessage={errors?.attendance?.message}
                />
              )}
            />
            <Button text="Confirmar" height="25" typeButton="submit" />
          </Form>
        </Modal>
        <Modal
          modalWidth={40}
          modalHeight={50}
          modalTitle={"Gerenciar o ambiente"}
          modalActive={isOpenEnvironment}
          modalSetActive={() => closeWindowModal()}
        >
          <ContentModalEnvironment>
            <LayerModalEnvironmentButtons>
              <ButtonPanel
                toTerminate
                onClick={() => encerrarAtendimento(handleClickEnvironment)}
              >
                <ButtonPanelTitle>Encerrar</ButtonPanelTitle>
                <ButtonPanelDescribe>
                  Encerrar atendimento atual no ambiente
                </ButtonPanelDescribe>
              </ButtonPanel>
              <ButtonPanel
                onClick={() => chamarNovamente(handleClickEnvironment)}
              >
                <ButtonPanelTitle>Chamar</ButtonPanelTitle>
                <ButtonPanelDescribe>
                  Chamar a mesma senha novamente.
                </ButtonPanelDescribe>
              </ButtonPanel>
            </LayerModalEnvironmentButtons>
          </ContentModalEnvironment>
        </Modal>
        <ContentKeys>
          <KeysContainer>
            <ContentKeysTitle>Senhas</ContentKeysTitle>
            <KeysMural>
              <ContentKeysMural>
                {senhasEncaminhadas.length > 0
                  ? senhasEncaminhadas.map((key, index) => (
                      <Key
                        key={index}
                        isSelected={index === activeKey.index ? 1 : 0}
                        handleClick={() => activeKeyList(index, key)}
                      >
                        {key.numero_senha}
                      </Key>
                    ))
                  : null}
              </ContentKeysMural>
            </KeysMural>
          </KeysContainer>
          <LogsContainer>
            <ContentKeysTitle>Encaminhamento de senhas</ContentKeysTitle>
            <KeysMural>
              <ContentLogsMural>
                {history.length > 0
                  ? history.map((log, index) => (
                      <div>
                        <LogContainer key={index.toString()}>
                          <KeyTitle> {log.numero_senha} </KeyTitle>
                          <p style={{ margin: "0px 20px" }}> - </p>
                          <EnvorimentTitle>{log.nome_ambiente}</EnvorimentTitle>
                        </LogContainer>
                      </div>
                    ))
                  : null}
              </ContentLogsMural>
            </KeysMural>
          </LogsContainer>
        </ContentKeys>
        <ContentGrid>
          <ContentEnvironment>
            <ContentEnvironmentTitle>Ambientes</ContentEnvironmentTitle>
            <StatusLayer>
              <AvailableCaptionEnvironment /> <p>Disponível</p>
              <BusyCaptionEnvironment /> <p>Ocupado</p>
            </StatusLayer>
            <EnvironmentMural>
              {ambientesDisponiveis.length > 0
                ? ambientesDisponiveis
                    .sort(orderByName)
                    .map((envoriment, index) => (
                      <Environments
                        key={index}
                        onClick={() => openDataEnvironment(envoriment)}
                      >
                        <EnvironmentStatus setStatus={envoriment.ocupado} />
                        {envoriment.nome_ambiente}
                      </Environments>
                    ))
                : null}
            </EnvironmentMural>
          </ContentEnvironment>
        </ContentGrid>
      </Content>
    </Layout>
  );
}
