import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Cookie from "js-cookie";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ReactSelect from "react-select";
import Select from "../../../components/Forms/Select";

import { FaEdit } from "react-icons/fa";

import { HiOutlineTrash } from "react-icons/hi";

import { SiMaildotru } from "react-icons/si";

import {
  openLoadingModal,
  closeLoadingModal,
} from "../../../store/Actions/loadingAction";
import { useTheme } from "styled-components";

import {
  ContentGlobal,
  Form,
  ManageUsers,
  ContentUsers,
  MessageError,
  Rows,
  RowItem,
  RowInfo,
  Item,
  RowAction,
  Icons,
  Actions,
} from "../styles";

import Button from "../../../components/Forms/Button";
import Input from "../../../components/Forms/Input";
import Api from "../../../services/apiService";
import Modal from "../../../components/Modal";
import { toast } from "../../../utils/toast";
import { formatPhone, sanitizeNumber } from "../../../utils/format";
import { ListHeader } from "../../../components/ListHeader";
import { getCount } from "../../../utils";
import Filter from "../../../components/Filter";
import {
  MdBusiness,
  MdLockOpen,
  MdOutlinePhone,
  MdQrCode,
} from "react-icons/md";
import { BiToggleRight, BiUser } from "react-icons/bi";
import Checkbox from "../../../components/Forms/Checkbox";
import { getComponentUnitsTable } from "../../../components/Users";

export default function Manage() {
  const theme = useTheme();
  const schema = yup.object().shape({
    profile: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.number(),
          perfil: yup.string(),
        })
      )
      .min(1, () => "Selecione pelo menos um perfil")
      .typeError("Campo obrigatório")
      .required("O campo perfil é obrigatório"),
    username: yup.string().required("O campo e-mail é obrigatório"),
    email: yup
      .string()
      .required("O campo e-mail é obrigatório")
      .email("Digite um e-mail válido"),
    name: yup.string().required("O campo nome é obrigatório"),
    password: yup.string().when("$isEdit", {
      is: false,
      then: (item) => item.required("O campo senha é obrigatório"),
    }),
    unit: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string(),
          unidade: yup.string(),
        })
      )
      .min(1, () => "Selecione pelo menos uma unidade")
      .typeError("Campo obrigatório")
      .required("Campo obrigatório"),
    phone: yup.string(),
    integratedBadge: yup.string().required("Código de crachá obrigatório"),
    status: yup.boolean().required("Status obrigatorio"),
  });

  const defaultValues = {
    profile: [],
    username: "",
    email: "",
    name: "",
    password: "",
    phone: "",
    unit: [],
    integratedBadge: "",
    status: true,
  };


  const dispatch = useDispatch();

  // const [unidade, setUnidade] = useState([]);
  const [userList, setUserList] = useState([]);
  const [isActive, setIsActive] = useState("none");
  const [isEdit, setIsEdit] = useState(false);
  const [userEdit, setUserEdit] = useState({});
  const [units, setUnits] = useState([]);
  const [profileList, setProfileList] = useState([]);
  const [offset, setOffset] = useState();
  const [limit, setLimit] = useState();
  const [usersCount, setUsersCount] = useState(0);
  const [search, setSearch] = useState("");
  const [searchStatus, setSearchStatus] = useState("0");
  const [ascOrder, setOrderAsc] = useState(false);

  const [toLoad, setToLoad] = useState(true);
  const [checked, setChecked] = useState(true);
  function handleCheckBoxChange() {
    setChecked(!checked);
  }
  async function onSubmit(data) {
    try {
      const newData = {
        nome: data.name,
        username: data.username,
        email: data.email,
        password: data.password,
        telefone: sanitizeNumber(data.phone),
        unidades: data.unit.map((u) => u.id),
        perfil: data.profile.map((u) => u.id),
        cod_usuario_integrado: data.integratedBadge || "",
        status: checked ? "0" : "1",
      };
      dispatch(openLoadingModal());
      await Api.post("/usuarios", newData);
      dispatch(closeLoadingModal());
      setToLoad(true);
      toast("success", "Cadastrado!", "Usuário cadastrado com sucesso!");
      closeWindow();
    } catch (error) {
      dispatch(closeLoadingModal());
      toast(
        "error",
        "Erro!",
        error?.response?.data?.message ||
          "Algum problema aconteceu. Tentar novamente, se repetir chamar o suporte!"
      );
    }
  }

  async function onSubmitUpdate(data) {
    try {
      const newData = {
        nome: data.name,
        username: data.username,
        email: data.email,
        telefone: sanitizeNumber(data.phone),
        perfil: data.profile.map((u) => u.id),
        unidades: data.unit.map((u) => u.id),
        cod_usuario_integrado: data.integratedBadge || "",
        status: checked ? "0" : "1",
      };
      dispatch(openLoadingModal());
      await Api.put(`/usuarios/${userEdit.id}`, newData);
      dispatch(closeLoadingModal());
      setToLoad(true);
      toast("success", "Cadastrado!", "Usuário cadastrado com sucesso!");
      closeWindow();
    } catch (error) {
      dispatch(closeLoadingModal());
      toast(
        "error",
        "Erro!",
        error?.response?.data?.message ||
          "Algum problema aconteceu, tentar novamente se repetir chamar o suporte!"
      );
    }
  }

  useEffect(() => {
    if (toLoad) {
      Promise.all([
        Api.get("/unidades", { params: { status: "0", limit: "50" } }),
        Api.get("/perfis"),
        loadUserData({
          limit,
          offset,
          asc: ascOrder,
          name: search,
          status: searchStatus,
        }),
      ])
        .then(([units, profile /*users*/]) => {
          setUnits(units.data);
          setProfileList(profile.data);
          // setUserList(users.data)
        })
        .finally(() => {
          setToLoad(false);
        });
    }
  }, [toLoad]);

  // function SelectUnit(id){
  //   let numberid = parseInt(id)
  //   let arr = [...unidade]
  //   arr.push(numberid)
  //   setUnidade(arr)
  // }

  // function RemoveUnit(id){
  //   let arr = [...unidade]
  //   for(var i = 0; i< arr.length; i++){
  //     if(arr[i].id === id){
  //       arr.splice(i,1)
  //     }
  //   }
  //   setUnidade(arr)
  // }

  function closeWindow() {
    setIsActive("none");
    setValue("name", "");
    setValue("username", "");
    setValue("password", "");
    setValue("phone", "");
    setValue("unit", "default");
    setValue("profile", "default");
    setValue("email", "");
    setValue("status", "0");
    setChecked(false);
    setIsEdit(false);
    reset();
  }

  function editUser(user) {
    const userUnits = user.unidades.map((u) => u.id);
    setIsActive("flex");
    setIsEdit(true);
    setValue("username", user.username);
    setValue("name", user.nome);
    setValue("email", user.email);
    setValue("phone", user.telefone);
    setValue("status", user.status || "0");
    setChecked(user.status === "0" ? true : false);
    setValue(
      "profile",
      profileList.filter((p) => user.perfil.includes(p.id))
    );
    setValue(
      "unit",
      units.filter((unit) => userUnits.includes(unit.id))
    );
    setValue("integratedBadge", user.cod_usuario_integrado);
    setUserEdit(user);
    clearErrors();
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: `#333333`,
      backgroundColor: "transparent",
      boxShadow: "none",
      borderRadius: "8px",
      padding: "0.3rem",
      paddingLeft: "30px",
      margin: "5px 0px",

      "::placeholder": {
        color: "hsl(0, 0%, 50%)",
      },
      ":hover": {
        borderColor: "#0280F8",
      },
      ":active, :focus": {
        borderColor: "#0280F8",
        boxShadow: "0px 0px 6px 0px #0280F8",
      },
    }),
    container: (provided, state) => ({
      ...provided,
      zIndex: "auto",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: `#0280F8`,
      svg: {
        fill: "#0280F8",
      },
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    menu: (provided, state) => ({
      ...provided,
      padding: "5px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
      borderLeft: state.isSelected ? `3px solid #0280F8` : "none",
      color: state.isSelected ? `#0280F8` : "inherit",

      "&:hover, &:active": {
        borderLeft: `3px solid #0280F8`,
        color: `#0280F8`,
        backgroundColor: "white",
      },
    }),
  };

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
    context: { isEdit },
    resolver: yupResolver(schema),
  });

  async function handlePageChange(limit, offset) {
    setLimit(limit);
    setOffset(offset);
  }

  async function handleDeleteUser(userId) {
    try {
      dispatch(openLoadingModal());
      const { data } = await Api.delete(`/usuarios/${userId}`);
      toast(
        "success",
        "Sucesso",
        data?.message || "Usuário removido com sucesso"
      );
      setToLoad(true);
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Não foi possível remover o usuário."
      );
    }
    dispatch(closeLoadingModal());
  }

  useEffect(() => {
    loadUserData({
      limit,
      offset,
      asc: ascOrder,
      name: search,
      status: searchStatus,
    });
  }, [limit, offset, ascOrder, search, searchStatus]);

  async function loadUserData({ limit, offset, name, asc, status }) {
    try {
      dispatch(openLoadingModal());
      const params = {
        limit,
        offset,
        sort: `${asc ? "-" : ""}nome`,
      };
      if (name) params.nome = name;
      if (status) params.status = status;
      const { data, headers } = await Api.get("/usuarios", {
        params,
      });
      const _count = getCount(headers);
      setUsersCount(_count);
      setUserList(data);
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response.data?.message || "Não foi possível carregar os usuários"
      );
    }
    dispatch(closeLoadingModal());
  }

  function handleCreateModal() {
    clearErrors();
    setIsActive("flex");
  }

  return (
    <ContentGlobal>
      <Modal
        modalWidth={50}
        modalTitle={!isEdit ? "Cadastrar" : "Editar"}
        modalActive={isActive}
        modalSetActive={() => closeWindow()}
      >
        <Form
          context={{ isEdit }}
          onSubmit={handleSubmit(isEdit ? onSubmitUpdate : onSubmit)}
        >
          <Controller
            control={control}
            name="unit"
            render={({ field: { onChange, value } }) => (
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <MdBusiness
                    style={{
                      marginLeft: "10px",
                      marginRight: "-32px",
                    }}
                  />
                  <div style={{ width: "100%" }}>
                    <ReactSelect
                      styles={customStyles}
                      placeholder="Selecione uma unidade"
                      options={units}
                      getOptionLabel={(option) => option.unidade}
                      getOptionValue={(option) => option.id}
                      onChange={onChange}
                      isMulti
                      noOptionsMessage={() => "Unidade não encontrada"}
                      value={value}
                      isDisabled={getValues("status") === "2"}
                    />
                  </div>
                </div>
                {errors.unit?.message ? (
                  <MessageError>{errors.unit?.message}</MessageError>
                ) : null}
              </>
            )}
          />
          <Controller
            control={control}
            name="profile"
            render={({ field: { onChange, value } }) => (
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <MdBusiness
                    style={{
                      marginLeft: "10px",
                      marginRight: "-32px",
                    }}
                  />
                  <div style={{ width: "100%" }}>
                    <ReactSelect
                      styles={customStyles}
                      placeholder="Selecione o perfil do usuário"
                      options={profileList}
                      getOptionLabel={(option) => option.perfil}
                      getOptionValue={(option) => option.id}
                      onChange={onChange}
                      isMulti
                      noOptionsMessage={() => "Perfil não encontrado"}
                      value={value}
                      isDisabled={getValues("status") === "2"}
                    />
                  </div>
                </div>
                {errors.profile?.message ? (
                  <MessageError>{errors.profile?.message}</MessageError>
                ) : null}
              </>
            )}
          />
          <Controller
            control={control}
            name="name"
            render={({ field: { onChange, value } }) => (
              <Input
                LeftIcon={<BiUser size={22} />}
                width="100"
                height="10"
                TextInput="Nome do usuário"
                typeInput={"text"}
                value={value}
                handleChange={onChange}
                errorMessage={errors.name?.message}
                disabled={getValues("status") === "2"}
              />
            )}
          />
          <Controller
            control={control}
            name="username"
            render={({ field: { onChange, value } }) => (
              <Input
                LeftIcon={<BiUser size={22} />}
                width="100"
                height="10"
                TextInput="Login do usuário"
                typeInput={"text"}
                value={value}
                handleChange={onChange}
                errorMessage={errors.username?.message}
                disabled={getValues("status") === "2"}
              />
            )}
          />
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, value } }) => (
              <Input
                LeftIcon={<SiMaildotru size={22} />}
                width="100"
                height="10"
                TextInput="E-mail do Usuário"
                typeInput={"email"}
                value={value}
                handleChange={onChange}
                errorMessage={errors.email?.message}
                disabled={getValues("status") === "2"}
              />
            )}
          />

          {!isEdit ? (
            <Controller
              control={control}
              name="password"
              render={({ field: { onChange, value } }) => (
                <Input
                  LeftIcon={<MdLockOpen size={22} />}
                  width="100"
                  height="10"
                  TextInput="Senha do usuário"
                  typeInput={"password"}
                  handleChange={onChange}
                  value={value}
                  errorMessage={errors.password?.message}
                />
              )}
            />
          ) : null}

          <Controller
            control={control}
            name="phone"
            render={({ field: { onChange, value } }) => (
              <Input
                LeftIcon={<MdOutlinePhone size={22} />}
                width="100"
                height="10"
                TextInput="Telefone do usuário"
                typeInput={"text"}
                value={formatPhone(value)}
                handleChange={onChange}
                valueMax={15}
                errorMessage={
                  errors.phone?.message ? "Digite um número válido!" : null
                }
                disabled={getValues("status") === "2"}
              />
            )}
          />

          <Controller
            control={control}
            name="integratedBadge"
            render={({ field: { onChange, value } }) => (
              <Input
                LeftIcon={<MdQrCode size={22} />}
                width="100"
                height="10"
                TextInput="Crachá do usuário"
                typeInput={"text"}
                value={value}
                handleChange={onChange}
                errorMessage={errors?.integratedBadge?.message}
                disabled={getValues("status") === "2"}
              />
            )}
          />

          <Controller
            control={control}
            name="status"
            render={() => (
              <Checkbox
                label={
                  isEdit
                    ? getValues("status") === "2"
                      ? "Excluído"
                      : "Habilitado"
                    : "Habilitado"
                }
                handleCheckBoxChange={handleCheckBoxChange}
                checked={
                  isEdit
                    ? getValues("status") === "2"
                      ? true
                      : checked
                    : checked
                }
                errrorMessage={errors?.status?.message}
              />
            )}
          />
          <Button
            text={isEdit ? "Atualizar" : "Cadastrar"}
            disabled={getValues("status") === "2"}
            typeButton={"submit"}
          />
        </Form>
      </Modal>
      <ManageUsers>
        <ContentUsers>
          <Filter
            title="Usuários"
            searchProps={{
              searchableItens: [{ label: "nome" }],
              handleSearch: (term) => setSearch(term),
            }}
            searchStatus={{
              searchableItens: [{ label: "status" }],
              handleSearchStatus: (term) => setSearchStatus(term),
            }}
            // handleSearchStatus={() => handleSearchStatus()}
            paginationProps={{
              count: usersCount,
              handlePageChange: (limit, offset) =>
                handlePageChange(limit, offset),
            }}
            actions={
              <Actions>
                <Button text="Cadastrar" HandleClick={handleCreateModal} />
              </Actions>
            }
          >
            <Rows>
              <ListHeader
                itens={[
                  {
                    canOrder: true,
                    order: "name",
                    label: "Nome",
                    asc: ascOrder,
                  },
                  { label: "Unidades" },
                  { label: "Status" },
                  { label: "Ações", isAction: true },
                ]}
                handleOrder={(order) => setOrderAsc((old) => !old)}
              />
              {userList.map((user, index) => (
                <RowItem key={index.toString()}>
                  <RowInfo>
                    <Item>{user.nome}</Item>
                  </RowInfo>
                  {getComponentUnitsTable(user.unidades, units)}
                  <RowInfo>
                    <Item>
                      {user?.status === "0"
                        ? "Habilitado"
                        : user?.status === "1"
                        ? "Desabilitado"
                        : user?.status === "2"
                        ? "Excluído"
                        : ""}
                    </Item>
                  </RowInfo>
                  <RowAction>
                    <Icons onClick={() => editUser(user)}>
                      <FaEdit color={theme.colors.primary} />
                    </Icons>
                    <Icons onClick={() => handleDeleteUser(user.id)}>
                      <HiOutlineTrash color={theme.colors.warning} />
                    </Icons>
                  </RowAction>
                </RowItem>
              ))}
            </Rows>
          </Filter>
        </ContentUsers>
      </ManageUsers>
    </ContentGlobal>
  );
}
