import {
  PANEL_HOME,
  PANEL_TO_MANAGE,
  PANEL_UNIT,
  PANEL_SYSTEM,
  PANEL_ENVIROMENTS,
  PANEL_PRIORITY,
  PANEL_FILA,
  PANEL_PROFILE,
} from "../actionTypes";

export function viewHome() {
  return {
    type: PANEL_HOME,
    payload: "Home",
  };
}

export function viewManage() {
  return {
    type: PANEL_TO_MANAGE,
    payload: "Manage",
  };
}

export function viewUnit() {
  return {
    type: PANEL_UNIT,
    payload: "Unit",
  };
}

export function viewSystem() {
  return {
    type: PANEL_SYSTEM,
    payload: "System",
  };
}

export function viewEnvironments() {
  return {
    type: PANEL_ENVIROMENTS,
    payload: "Environments",
  };
}

export function viewPriority() {
  return {
    type: PANEL_PRIORITY,
    payload: "PRIORITY",
  };
}

export function viewFila() {
  return {
    type: PANEL_FILA,
    payload: "Fila",
  };
}

export function viewProfile() {
  return {
    type: PANEL_PROFILE,
    payload: "PROFILE",
  };
}
