import styled from "styled-components";

export const Content = styled.div`
  flex: 1;
  margin-left: 15px;
  height: 100%;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.div`
  border-radius: 10px 10px 0 0;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.primary};
  font-size: 20px;
  font-weight: 600;
`;

export const History = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
`;

export const ContainerKeys = styled.div`
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  transition: 0.3s;
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow-x: hidden;
  padding: 10px;
  flex-wrap: wrap;
  max-height: 50vh;
`;

export const ContainerAtendimento = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  transition: 0.3s;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 30px;
  margin-bottom: 30px;
`;

export const ContainerAtendimentoAtivo = styled.div`
  width: 100%;
  height: 100%;
  background-color: #e5e5e5;
  border-radius: 10px;
  transition: 0.3s;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 2fr 8fr;
  grid-gap: 20px;
  padding: 10px;
`;

export const Key = styled.div`
  flex: 1;
  background-color: ${({ theme, setActive }) =>
    setActive ? theme.colors.secondary : theme.colors.lightGray};
  border-radius: 10px;
  font-size: 70px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) =>
    props.setActive ? props.theme.colors.white : props.theme.colors.primary};
`;

export const LatestPassword = styled.div`
  margin: 0 5px;
  height: 72px;
  margin-bottom: 10px;
  padding: 0 10px;
  background-color: #fff;
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.secondary};
  color: ${(props) => props.theme.colors.white};
  font-size: 1.7rem;
  display: flex;
`;

export const AttendanceLayer = styled.div`
  flex: 1;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const DadosKey = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 40px;
`;

export const ButtonEncerrar = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonRed = styled.button`
  flex: 1;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.warning};
  border: none;
  font-size: 14px;
  color: #fff;
  padding: 15px 0;
  transition: 0.2s;
  margin-left: 16px;

  :hover {
    transform: scale(1.03);
  }
`;

export const ButtonBlue = styled(ButtonRed)`
  background-color: ${(props) => props.theme.colors.primary};
  margin-left: 0px !important;
  margin-right: 16px;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 32px;
`;

export const Table = styled.table`
  margin: 40px;
  align-self: center;
`;

export const Row = styled.tr``;

export const Column = styled.td`
  padding: 5px;
  padding-right: 10px;
`;

export const AttendanceContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
