import { USER_lOGIN_IN, USER_LOGOUT } from '../actionTypes'
import Cookie from 'js-cookie';

const user = JSON.parse(Cookie.get('user') || '{}');

const initialState = {
  user: {
    id: user.id || '',
    name: '',
    username: user.username || '',
    email: user.email || '',
    isadmin: user.isadmin || '',
    token: user.token || '',
    perfil: user.perfil || [],
    unidades: user.unidades || [],
    cod_usuario_integrado: user.cod_usuario_integrado || '',
  }
}

export default function userReducer(state = initialState, action){
  switch(action.type){
    case USER_lOGIN_IN:
      return { user: {...action.payload.user, unidades: action.payload.unidades} };
    case USER_LOGOUT:
      return {...state, ...action.payload};
    default:
      return state
  }
}

