import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FaRegIdCard } from "react-icons/fa";
import { AiOutlineHome } from 'react-icons/ai'
import { IoMdBusiness } from 'react-icons/io'
import Menu from '../Forms/Menu'

import { BsDiagram2, BsListUl } from "react-icons/bs";

import {
  Content,
  Container,
  ContentButtons,
  Button,
  Label,
  Anchor,
} from "./styles";

import { IoIosBarcode } from "react-icons/io";

import api from '../../services/apiService';

import {
  viewHome,
  viewManage,
  viewPriority,
  viewUnit,
} from "../../store/Actions/panelAction";
// import { setPanelShowOptions } from "../../store/Actions/clinicAction";
import { MANAGER, SUPERVISOR } from "../../utils/constants";
import Cookies from "js-cookie";

export default function Aside({
  admin = false,
  showAttendance = false,
  showAllAttendance = false,
}) {
  const token = Cookies.get("token");
  const [isOpen] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [unitConfig, setUnitConfig] = useState({});
  const { user } = useSelector((state) => state.user);
  const { panelView } = useSelector((state) => state.panel);
  const { unidade, showOptions } = useSelector((state) => state.clinic);

  function goToGeneratePassword() {
    return `/totem/${unidade.id}`;
  }

  function goToUnit() {
    // dispatch(setPanelShowOptions(false));
    return "/unidade";
  }

  function goToSharedAttendance() {
    return `/compartilhado/${unidade.id}`;
  }

  async function fetchUnitConfig(unidadeId) {
    try {
      const { data } = await api.get(`/unidades/${unidadeId}/config`);
      setUnitConfig(data);
    } catch(error) {
      console.log("Não foi encontrado configurações para essa unidade");
    }
  }

  useEffect(() => {
    if (unidade?.id && token) {
      fetchUnitConfig(unidade.id);
    }
  }, [unidade]);

  return (
    <Content open={isOpen} showAttendance={showAttendance}>
      {admin ? (
        <Container>
          <ContentButtons>
            <>
              {user.perfil[0] !== SUPERVISOR && user.perfil[0] !== MANAGER ? (
                <Button
                  title="Início"
                  open={isOpen}
                  isSelected={panelView === "Home"}
                  onClick={() => {
                    if (location.pathname !== "/admin") navigate("/admin");
                    dispatch(viewHome());
                  }}
                >
                  <AiOutlineHome />
                  {isOpen ? <Label>Início</Label> : null}
                </Button>
              ) : null}
              <Button
                title="Usuários"
                open={isOpen}
                isSelected={panelView === "Manage"}
                onClick={() => {
                  if (location.pathname !== "/admin") navigate("/admin");
                  dispatch(viewManage());
                }}
              >
                <FaRegIdCard size={22} />
                {isOpen ? <Label>Usuários</Label> : null}
              </Button>
              <Button
                title="Prioridades"
                open={isOpen}
                isSelected={panelView === "PRIORITY"}
                onClick={() => {
                  if (location.pathname !== "/admin") navigate("/admin");
                  dispatch(viewPriority());
                }}
              >
                <BsListUl />
              </Button>
              <Button
                title="Unidades"
                open={isOpen}
                isSelected={panelView === "Unit"}
                onClick={() => {
                  if (location.pathname !== "/admin") navigate("/admin");
                  dispatch(viewUnit());
                }}
              >
                <IoMdBusiness size={22} />
                {isOpen ? <Label>Unidades</Label> : null}
              </Button>
            </>
          </ContentButtons>
        </Container>
      ) : null}
      {showAttendance && showOptions ? (
        <>
          {showAllAttendance ? (
            <>
              <Anchor title="Selecionar unidade" to={goToUnit()}>
                <IoMdBusiness />
              </Anchor>
              <Anchor
                title="Ambiente compartilhado"
                to={goToSharedAttendance()}
              >
                <BsDiagram2 size={24} />
              </Anchor>
            </>
          ) : null}
          <Anchor title="Gerar senha" to={goToGeneratePassword()}>
            <IoIosBarcode />
          </Anchor>
          <Menu 
                unidade={
                  {
                    id: unidade.id,
                    token: unitConfig?.token
                  } 
                }
              />
        </>
      ) : null}
    </Content>
  );
}
