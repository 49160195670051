import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  padding: 1.2rem 1rem;
`;
export const Body = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  position: relative;
  padding: 1.2rem 1rem;
`;

export const Title = styled.div`
  text-align: center;
  font-size: 2rem;
  line-height: 3rem;
  color: ${(props) => props.theme.colors.secondary};
  flex: 1;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ButtonContent = styled.div`
  min-width: 200px;
  > button {
    margin: 0;
    width: 100%;
    padding: 1rem 1.5rem;
  }
  &.voltar {
    button {
      max-width: 4rem;
    }
  }
`;

export const FormLogin = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;

  max-height: 90vh;
  max-width: 30rem;
  width: 100%;
  overflow-y: auto;

  border-radius: 10px;
  padding: 1.25rem 1.25rem 2.5rem 1.25rem;
  background-color: ${(props) => props.theme.colors.login};

  @media (max-width: 768px) {
    max-width: 70vw;
    padding: 1rem 1rem 2rem 1rem;
  }

  @media (max-width: 600px) {
    max-width: 95vw;
    padding: 0.8rem 0.8rem 1.5rem 0.8rem;
  }
`;

export const ContentInput = styled.div`
  width: 100%;
  margin-bottom: 0.8rem;
  display: flex;
  flex-direction: column;

  input {
    ::placeholder {
      color: hsl(0, 0%, 50%);
    }
  }

  input[type="date"]:invalid::-webkit-datetime-edit {
    color: hsl(0, 0%, 50%);
  }
`;
export const ContentButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > button {
    max-width: 60%;
    padding: 1rem 1.5rem;

    @media (max-width: 768px) {
      max-width: 80%;
    }

    @media (max-width: 600px) {
      max-width: 100%;
    }
  }
`;

export const MessageError = styled.p`
  font-size: 0.8rem;
  margin-top: 4px;
  font-weight: 300;
  width: 100%;
  color: ${(props) => props.theme.colors.warning};
`;

export const Label = styled.p`
  font-size: 0.9rem;
  line-height: 1.5rem;
  font-weight: 600;
  width: 100%;
  color: #333333;
`;
