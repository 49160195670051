import React from 'react';

import {
  Content,
  ContainerModal,
  ContainerTitleModal,
  ContainerBodyModal,
  CloseWindow
} from './styles';

import {
  FaTimes
} from 'react-icons/fa';

export default function Modal({
  modalWidth = 100,
  modalHeight = 100,
  modalTitle = 'Modal',
  modalActive = 'none',
  modalSetActive,
  children
}){
  return(
    <Content setActive={modalActive}>
      <ContainerModal setWidth={modalWidth} setHeight={modalHeight}>
        <ContainerTitleModal getHeight={modalHeight <= 50 ? '20%' : '10%'}>
          {modalTitle}
        <CloseWindow onClick={() => modalSetActive()}><FaTimes color="red" /></CloseWindow>
        </ContainerTitleModal>
        <ContainerBodyModal>
          {children}          
        </ContainerBodyModal>
      </ContainerModal>
    </Content>
  )
}