export const OPEN_LOADING = "OPEN_LOADING";

export const CLOSE_LOADING = "CLOSE_LOADING";

export const PANEL_HOME = "PANEL_HOME";

export const PANEL_TO_MANAGE = "PANEL_TO_MANAGE";

export const PANEL_UNIT = "PANEL_UNIT";

export const PANEL_SYSTEM = "PANEL_SYSTEM";

export const PANEL_ENVIROMENTS = "PANEL_ENVIROMENTS";

export const PANEL_PRIORITY = "PANEL_PRIORITY";

export const PANEL_FILA = "PANEL_FILA";

export const PANEL_PROFILE = "PANEL_PROFILE";

export const USER_lOGIN_IN = "USER_lOGIN_IN";

export const USER_LOGOUT = "USER_LOGOUT";

export const UNIT_USER = "UNIT_USER";

export const START_CRONOMETER = "START_CRONOMETER";

export const END_CRONOMETER = "END_CRONOMETER";

export const SET_CLINIC = "SET_CLINIC";

export const SET_UNIT = "SET_UNIT";

export const SET_GUICHE = "SET_GUICHE";

export const PANEL_OPTIONS_SHOW_ALL = "PANEL_OPTIONS_SHOW_ALL";
