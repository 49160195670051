import styled, { css } from "styled-components";
import { Link } from 'react-router-dom';

export const Content = styled.div`
  flex: 1;
  max-width: 90px;
  background-color: ${props => props.theme.colors.white};
  position: relative;
  transition: 0.2s;
  border-right-width: 1px;
  border-right-color: ${({ theme }) => theme.colors.secondary};
  border-right-style: solid;

  ${({ showAttendance }) => showAttendance ? css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  ` : css``}
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const ContainerIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${props => props.theme.colors.secondary};
  position: absolute;
  right:  ${ props => props.setPosition === true ? '-16px': '20%' };
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.white};
  cursor: pointer;
  transition: 0.2s;
  :hover{
    opacity: 0.8;
  }
`;

export const ContentButtons = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button`
  background: none;
  background-color: ${({ theme, isSelected }) => theme.colors[isSelected ? 'primary' : 'white']};
  display: flex;
  align-items: center;
  justify-content: ${ props => props.open === true ? 'start' : 'center' };
  cursor: pointer;
  transition: 0.2s;
  padding: 15px;
  margin: 5px;
  border: 1px solid ${({ theme, isSelected }) =>
    theme.colors[isSelected ? 'primary': 'white']};
  border-radius: 8px;

   > svg {
    fill: ${({ theme, isSelected }) =>
      theme.colors[isSelected ? 'white' : 'primary']};
    color: ${({ theme, isSelected }) =>
      theme.colors[isSelected ? 'white' : 'primary']};
  }
  
  :hover{
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Anchor = styled(Link)`
  background: none;
  display: flex;
  align-items: center;
  justify-content: ${ props => props.open === true ? 'start' : 'center' };
  color: ${props => props.theme.colors.white};
  cursor: pointer;
  border-radius: 8px;
  transition: 0.2s;
  padding: 15px;
  margin: 5px 0;
  border: 1px solid ${({ theme }) => theme.colors.white};

  :hover{
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Label = styled.label`
  cursor: pointer;
  font-size: 18px;
  margin-left: 20px;
  transition: 0.2s;
`;