import React, { useEffect } from "react";
import Cookie from "js-cookie";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Form } from "../../../styles";

import { FaBuilding, FaEdit } from "react-icons/fa";

import Api from "../../../../../services/apiService";
import Modal from "../../../../../components/Modal";
import Input from "../../../../../components/Forms/Input";
import Select from "../../../../../components/Forms/Select";
import Button from "../../../../../components/Forms/Button";
import { toast } from "../../../../../utils/toast";
import { CgArrowsV } from "react-icons/cg";

const schema = yup.object().shape({
  order: yup.string().required("O campo ordem é obrigatório."),
  kind: yup.string().required("O tipo de atendimento é obrigatório."),
  description: yup.string().required("A descrição é obrigatória."),
});

export default function KindAttendance({
  tipoAtendimento,
  setToLoad,
  isCreateTipoAtendimento,
  closeWindowModal,
  unit,
}) {

  const isUpdateType = tipoAtendimento?.id ? true :  false

  const defaultValues = {
    order: "",
    kind: "",
    description: "",
  };

  const {
    control,
    setValue,
    clearErrors,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  useEffect(() => {
    if (!isUpdateType) {
      reset()
      return;
    }

    if (isUpdateType) {
      setValue("kind", tipoAtendimento.tipo_atendimento);
      setValue("description", tipoAtendimento.desc_tipo_atendimento);
      setValue("order", tipoAtendimento.ordem)
      clearErrors();
    } 

  }, [isUpdateType, tipoAtendimento]);

  async function onSubmitKindOfAttendance(data) {
    try {
      let newData = {
        tipo_atendimento: data.kind,
        desc_tipo_atendimento: data.description,
        ordem: parseFloat(data.order),
        unidade_id: [unit],
      };
      await Api.post("/tipo-atendimentos", newData);
      toast("success", "Excelente", "Tipo de atendimento criado com sucesso!");
      closeWindowModal();
      reset();
      setToLoad(true);
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message ||
          "Não foi possível criar o tipo de atendimento."
      );
    }
  }

  async function onSubmitKindOfAttendanceUpdate({kind, description, order}) {
    try {
      const payload = {
        tipo_atendimento: kind,
        desc_tipo_atendimento: description,
        ordem: parseFloat(order),
      };
      if(!tipoAtendimento.id){
        throw "Não foi possível atualizar o Tipo de atendimento"
      }
      const { data } = await Api.put(`/tipo-atendimentos/${tipoAtendimento.id}`, payload);
      toast(
        "success",
        "Sucesso",
        data?.message || "O Tipo de atendimento foi atualizado com sucesso!"
      );
      closeWindowModal();
      setToLoad(true);
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Não foi possível atualizar o Tipo de atendimento"
      );
    }
  }

  return (
    <Modal
      modalWidth={50}
      modalHeight={60}
      modalTitle={(isUpdateType ? "Editar" : "Cadastrar") + " tipo de atendimento"}
      modalActive={isCreateTipoAtendimento}
      modalSetActive={() => closeWindowModal()}
    >
      <Form>
        <Controller
          control={control}
          name="kind"
          render={({ field: { onChange, value } }) => (
            <Input
              LeftIcon={<FaBuilding size={22} />}
              width="100"
              height="20"
              TextInput="Digite o tipo do atendimento"
              typeInput={"text"}
              handleChange={onChange}
              errorMessage={errors?.kind ? errors.kind?.message : null}
              value={value}
            />
          )}
        />
        <Controller
          control={control}
          name="description"
          render={({ field: { onChange, value } }) => (
            <Input
              LeftIcon={<FaEdit size={22} />}
              width="100"
              height="20"
              TextInput="Digite uma breve descrição do tipo de atendimento"
              typeInput={"text"}
              value={value}
              handleChange={onChange}
              errorMessage={
                errors?.description ? errors.description?.message : null
              }
            />
          )}
        />
        <Controller
          control={control}
          name="order"
          render={({ field: { onChange, value } }) => (
            <Select
              width="100"
              height="20"
              LeftIcon={<CgArrowsV size={22} />}
              defaultTitle="Selecione a ordem"
              value={value}
              handleChange={onChange}
              errorMessage={errors?.order ? errors.order?.message : null}
            >
              <option value={"0.2"}>Crescente</option>
              <option value={"0.3"}>Decrescente</option>
            </Select>
          )}
        />
        <Button
          width={30}
          height={25}
          text={isUpdateType ? "Atualizar" : "Cadastrar"}
          typeButton="button"
          HandleClick={handleSubmit(
            isUpdateType ? onSubmitKindOfAttendanceUpdate : onSubmitKindOfAttendance
          )}
        />
      </Form>
    </Modal>
  );
}
