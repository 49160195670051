import React from "react";

import {
  Content
} from './styles'

export default function Button({
  text = 'Button', 
  typeButton = 'button', 
  endIcon = false,
  HandleClick,
  disabled = false,
  color,
  textColor,
}){
  return(
    <Content
      color={color}
      textColor={textColor}
      type={typeButton}
      disabled={disabled}
      onClick={() => typeButton === 'submit' || disabled ? null : HandleClick()}
    >
      {text}
    </Content>
  )
}