import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.span`
  font-size: 24px;
  color: ${(props) => props.theme.colors.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${({ hasMargin }) => (hasMargin ? 50 : 0)}px;
  margin-bottom: ${({ hasMargin }) => (hasMargin ? 10 : 0)}px;
`;

export const ContainerTypeAttendance = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
`;

export const ContainerPasswordsType = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  row-gap: 1rem;
  column-gap: 2rem;

  & > div > button {
    height: 100%;
  }

  @media (width < 490) {
    grid-template-columns: 1fr;
  }
  
`;

export const Priorities = styled.div`
  display: grid;
  width: 100%;
  padding: 0.5rem 2rem;
  overflow: auto;
  max-height: calc(100vh - 245px); /* TODO: Replace size calc */
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  margin: 15px 30px;
`;

export const Button = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 15px 20px;
  margin: 5px 15px;
  border-radius: 16px;
  cursor: pointer;
  transition: 0.2s transform;

  :hover {
    transform: scale(1.05);
  }
`;

export const ButtonInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonTitle = styled.span`
  color: #fbfbfb;
  font-size: 24px;
`;

export const ButtonDescription = styled(ButtonTitle)`
  font-size: 18px;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  padding: 10px;
  justify-content: space-between;

  > button {
    /* TODO: Replace button customization */
    background-color: ${({ theme }) => theme.colors.primary};
    margin: 10px 20%;
  }

  > svg {
    align-self: center;
    margin-top: 20px;

    > path {
      stroke: blue;
    }
  }
`;
