import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme, bgColor }) => bgColor || theme.colors.white};
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.secondary};
  border-style: solid;
  border-radius: 8px;
  padding: 5px;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0px;
`;

export const ContainerSelect = styled.select`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;

  border: none;
  transition: 0.3s;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.3rem 0rem;
  background-color: inherit;
  cursor: pointer;
  color: #0b0b0b;
  flex: 1;
  border-radius: 8px;

  :hover {
    border-color: ${(props) => props.theme.colors.primary};
  }
  :active {
    border-color: ${(props) => props.theme.colors.primary};
    -webkit-box-shadow: 0px 0px 6px 0px ${(props) => props.theme.colors.primary};
    box-shadow: 0px 0px 6px 0px ${(props) => props.theme.colors.primary};
  }
`;

export const Icon = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.tertiary};
`;

export const IconSelect = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.tertiary};
  cursor: pointer;
`;

export const MessageError = styled.p`
  font-size: 12px;
  font-weight: 300;
  width: 100%;
  color: ${(props) => props.theme.colors.warning};
`;
