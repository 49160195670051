import { format } from 'date-fns';
import { getDate } from ".";

function sanitizeNumber(value) {
  return value.replace(/\D/g, "");
}

function formatPhone(phoneNumber) {
  phoneNumber = sanitizeNumber(phoneNumber);
  phoneNumber = phoneNumber.replace(/(\d{2})(\d)/, "($1) $2");
  phoneNumber = phoneNumber.replace(/(\d{4,5})(\d{4})/, "$1-$2");
  return phoneNumber;
}

function formatCep(cep) {
  cep = sanitizeNumber(cep);
  cep = cep.replace(/(\d{5})(\d)/, "$1-$2");
  return cep;
}

function formatCpf(document) {
  document = document && document.replace(/\D/g, "");
  document = document && document.replace(/(\d{3})(\d)/, "$1.$2");
  document = document && document.replace(/(\d{3})(\d)/, "$1.$2");
  document = document && document.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  return document;
}

function formatDate(date) {
  date = getDate(date);
  return date.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1');
}

export function getTime(date) {
  if (!date) return "-"
  date = new Date(date);
  return format(date, 'HH:mm:ss');
}

function getPriorityAbbreviation(key) {
  if (key) {
    const abbreviation = key.split(" ");
    abbreviation.pop();
    return abbreviation.join(" ");
  }
  return "";
}


function getKeyNumber(key) {
  if (key) {
    const parts = key.split(" ");
    if (parts.length > 0) {
      const keyNumber = parts.pop();
      return `${keyNumber.length === 1 ? '0' : ''}${keyNumber}`;
    }
  }
  return "";
}

export {
  sanitizeNumber,
  formatPhone,
  formatCep,
  formatCpf,
  formatDate,
  getKeyNumber,
  getPriorityAbbreviation,
};
