import { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { TiSortNumerically } from "react-icons/ti";
import ReactSelect from "react-select";
import Input from "../Forms/Input";
import Api from "../../services/apiService";
import { toast } from "../../utils/toast";
import {
  Container,
  Content,
  FilterButton,
  MessageError,
  Spinner,
  SpinnerContainer,
  Title,
} from "./styles";
import { useDispatch } from "react-redux";
import { closeLoadingModal, openLoadingModal } from '../../store/Actions/loadingAction';

export default function RevalidatePassword({ unitId }) {
  const NORMAL_ID = "NORMAL_ID";
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [priorities, setPriorities] = useState();
  const defaultValues = { key: {}, number: "" };

  const schema = yup.object().shape({
    key: yup
      .object()
      .shape({
        sigla: yup.string().matches(/^[^0-9]*$/, 'Não é permitido números'),
      })
      .nullable(false)
      .required("Sigla da senha obrigatória"),
    number: yup
      .number()
      .positive("Informe um número positivo")
      .typeError("Informe apenas a numeração da senha")
      .required("Numeração da senha obrigatória.")
  });

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  async function revalidateKey(payload) {
    try {
      setLoading(true);
      const { data } = await Api.patch(
        "/senhas/revalidar",
        {
          codigo_senha: payload.key.sigla,
          numero_senha: payload.number,
          unidade_id: unitId,
        });
      toast("success", "Sucesso", data?.message || "Senha revalidada");
      reset();
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Não foi possível revalidar a senha.",
      );
    }
    setLoading(false);
  }

  async function loadPriorities() {
    try {
      dispatch(openLoadingModal());
      const { data } = await Api.get("/prioridades", {
        params: { unidade_id: unitId },
      });
      const normalPriority = {
        id: NORMAL_ID,
        sigla: 'N',
      };
      setValue('key', normalPriority);
      setPriorities([normalPriority, ...data]);
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Não possível buscar as prioridades"
      );
    }
    dispatch(closeLoadingModal());
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: `#333333`,
      backgroundColor: "transparent",
      boxShadow: "none",
      borderRadius: "8px",
      padding: "0.3rem",
      paddingLeft: "3",
      margin: "5px 0px",
      width: 150,

      "::placeholder": {
        color: "hsl(0, 0%, 50%)",
      },
      ":hover": {
        borderColor: "#0280F8",
      },
      ":active, :focus": {
        borderColor: "#0280F8",
        boxShadow: "0px 0px 6px 0px #0280F8",
      },
    }),
    container: (provided, state) => ({
      ...provided,
      zIndex: 5,
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: `#0280F8`,
      svg: {
        fill: "#0280F8",
      },
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    menu: (provided, state) => ({
      ...provided,
      padding: "5px",
      top: "-10rem",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
      borderLeft: state.isSelected ? `3px solid #0280F8` : "3px solid transparent",
      color: state.isSelected ? `#0280F8` : "inherit",

      "&:hover, &:active": {
        borderLeft: `3px solid #0280F8`,
        color: `#0280F8`,
        backgroundColor: "white",
      },
    }),
  };

  useEffect(() => {
    loadPriorities();
  }, []);

  return (
    <Container>
      <Title>Revalidar senha perdida</Title>
      <Content>
        <Controller 
          control={control}
          name="key"
          render={({ field: { onChange, value } }) => (
            <div style={{ flexDirection: 'column' }}>
              <ReactSelect
                styles={customStyles}
                placeholder="Sigla"
                options={priorities}
                getOptionLabel={(option) => option.sigla}
                getOptionValue={(option) => option.id}
                onChange={onChange}
                noOptionsMessage={() => "Prioridades não encontradas"}
                value={value}
              />
              {errors?.key?.message ? (
                <MessageError>{errors?.key?.message}</MessageError>
              ) : null}
            </div>
          )}
        />
        <Controller
          control={control}
          name="number"
          render={({ field: { onChange, value } }) => (
            <Input
              style={{ flex: 3 }}
              LeftIcon={<TiSortNumerically />}
              TextInput="Informe a senha"
              value={value}
              handleChange={onChange}
              autofocus
              typeInput="number"
              errorMessage={errors?.number ? errors?.number?.message : undefined}
              valueMin="0"
              valueStep="1"
              forceRangeValue
            />
          )}
        />
        <FilterButton
          disabled={loading}
          onClick={loading ? undefined : handleSubmit(revalidateKey)}
        >
          {loading ? (
            <SpinnerContainer>
              <Spinner size={15} />
            </SpinnerContainer>
          ) : (
            "Revalidar"
          )}
        </FilterButton>
      </Content>
    </Container>
  );
}
