import React, { useEffect, useRef, useState } from "react";
import {
  MdOutlinePersonOutline,
  MdCalendarToday,
  MdBusiness,
} from "react-icons/md";
import { Controller, useForm } from "react-hook-form";
import ReactSelect from "react-select";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import Api from "../../services/apiService";
import LogoLogin from "../../assets/Logos/GroupLogo.svg";

import {
  ContentForm,
  Content,
  ContainerLogin,
  FormLogin,
  ContentInput,
  ContentLogo,
  ContentButton,
  MessageError,
  Label,
} from "./styles";

import Button from "../../components/Forms/Button";
import Input from "../../components/Forms/Input";

import { toast } from "../../utils/toast";
import validateDocument from "../../utils/validateDocument";
import { formatCpf } from "../../utils/format";
import ToPrint from "../../components/ToPrintESenha";
import { useReactToPrint } from "react-to-print";

export default function ESenha() {
  var today = new Date();
  today.setHours(0, 0, 0, 0);

  const dayinitial = new Date(today).toISOString();
  const dayLimit = new Date(
    `${today.getFullYear()}-${today.getMonth() + 2}-${today.getDate()}`
  ).toISOString();

  const schema = yup
    .object({
      document: yup
        .string()
        .required("Campo obrigatório")
        .min(14, "CPF inválido")
        .test("documentValidation", "CPF inválido", (value) =>
          validateDocument(value)
        ),
      unit: yup.object().shape({
        label: yup.string(),
        value: yup.string().required("Campo obrigatório"),
      }),
      date: yup
        .date()
        .nullable()
        .transform((curr, orig) => (orig === "" ? null : curr))
        .required("Campo obrigatório")
        .min(dayinitial, "Data inválida")
        .max(dayLimit, "Data inválida"),
      name: yup.string().required("Campo obrigatório"),
    })
    .required();

  const defaultValues = {
    document: "",
    name: "",
    unit: undefined,
  };

  const [units, setUnits] = useState([]);

  const [selectedUnitName, setSelectedUnitName] = useState("");
  const [loading, setLoading] = useState(true);

  const [activePrint, setActivePrint] = useState(false);
  const [key, setKey] = useState({});
  let componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  async function onSubmit(data) {
    if (data === undefined) {
      return null;
    }
    let newData = {
      nome_cliente_agendamento: data.name,
      documento_cliente_agendamento: data.document
        .replaceAll(".", "")
        .replaceAll("-", ""),
      data_agendamento: data.date,
      unidade_id: data?.unit?.value,
    };

    try {
      toast("info", "Aguarde!", "Estamos gerando sua senha");
      const response = await Api.post(`/senhas/e-senha`, newData);
      setKey(response.data);
      setActivePrint(true);
      handlePrint();
      toast(
        "success",
        "Imprimindo!!!",
        "Retire sua senha e valide na recepção."
      );
      reset();
      setValue("");
      setActivePrint(false);
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Não foi possível gerar sua senha"
      );
    }
  }

  useEffect(() => {
    Promise.all([
      Api.get("/unidades/public", {
        params: {
          sort: `-unidade`,
          status: '0',
        },
      }),
    ])
      .then(([units]) => {
        setUnits(
          units.data.map((items) => ({
            label: items.unidade,
            value: items.id,
          }))
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: `#333333`,
      backgroundColor: "transparent",
      boxShadow: "none",
      borderRadius: "8px",
      padding: "0.3rem",
      paddingLeft: "30px",
      margin: "5px 0px",

      "::placeholder": {
        color: "hsl(0, 0%, 50%)",
      },
      ":hover": {
        borderColor: "#0280F8",
      },
      ":active, :focus": {
        borderColor: "#0280F8",
        boxShadow: "0px 0px 6px 0px #0280F8",
      },
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: `#0280F8`,
      svg: {
        fill: "#0280F8",
      },
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    menu: (provided, state) => ({
      ...provided,
      padding: "5px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
      borderLeft: state.isSelected ? `3px solid #0280F8` : "none",
      color: state.isSelected ? `#0280F8` : "inherit",

      "&:hover, &:active": {
        borderLeft: `3px solid #0280F8`,
        color: `#0280F8`,
        backgroundColor: "white",
      },
    }),
  };

  const {
    control,
    reset,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  return (
    <Content>
      <ContainerLogin>
        <FormLogin onSubmit={handleSubmit(onSubmit)}>
          <ContentLogo>
            <img src={LogoLogin} alt="Logo Softlab" />
          </ContentLogo>
          <ContentForm>
            <ContentInput>
              <Label>
                Unidade de atendimento <span style={{ color: "red" }}>*</span>
              </Label>
              {/* {!loading && (
                <Controller
                  control={control}
                  name="unit"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      bgColor={theme.colors.white}
                      LeftIcon={<MdBusiness />}
                      value={value}
                      defaultTitle="Selecione uma unidade"
                      handleChange={(e) => {
                        clearErrors("unit");
                        setValue("unit", e);
                        setSelectedUnitName(e.label);
                      }}
                    >
                      {units.map((unit, index) => {
                        return (
                          <option key={index.toString()} value={unit.value}>
                            {unit.label}
                          </option>
                        );
                      })}
                    </Select>
                  )}
                />
              )} */}

              {!loading && (
                <Controller
                  control={control}
                  name="unit"
                  render={({ field: { onChange, value } }) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <MdBusiness
                        style={{
                          marginLeft: "10px",
                          marginRight: "-32px",
                        }}
                      />
                      <div style={{ width: "100%" }}>
                        <ReactSelect
                          value={value}
                          styles={customStyles}
                          placeholder="Selecione uma unidade"
                          noOptionsMessage={() => "Nenhuma unidade disponível"}
                          onChange={(e) => {
                            clearErrors("unit");
                            setValue("unit", e);
                            setSelectedUnitName(e.label);
                          }}
                          options={units}
                        />
                      </div>
                    </div>
                  )}
                />
              )}
              {errors.unit && errors.unit?.value?.message ? (
                <MessageError>{errors.unit?.value?.message}</MessageError>
              ) : null}
            </ContentInput>

            <ContentInput>
              <Label>
                Nome <span style={{ color: "red" }}>*</span>
              </Label>
              <Controller
                control={control}
                name="name"
                render={({ field: { onChange, value } }) => (
                  <Input
                    LeftIcon={<MdOutlinePersonOutline />}
                    TextInput="Nome"
                    value={value}
                    handleChange={(e) => onChange(e)}
                    typeInput={"text"}
                    errorMessage={errors.name?.message}
                  />
                )}
              />
            </ContentInput>
            <ContentInput>
              <Label>
                Documento <span style={{ color: "red" }}>*</span>
              </Label>
              <Controller
                control={control}
                name="document"
                render={({ field: { onChange, value } }) => (
                  <Input
                    LeftIcon={<MdOutlinePersonOutline />}
                    TextInput="CPF"
                    typeInput={"text"}
                    value={value}
                    valueMaxLength={14}
                    handleChange={(text) => onChange(formatCpf(text))}
                    errorMessage={errors.document?.message}
                  />
                )}
              />
            </ContentInput>
            <ContentInput>
              <Label>
                Data de agendamento <span style={{ color: "red" }}>*</span>
              </Label>
              <Controller
                control={control}
                name="date"
                render={({ field: { onChange, value } }) => (
                  <Input
                    id="date"
                    LeftIcon={<MdCalendarToday size={22} />}
                    valueMin={dayinitial.substring(0, 10)}
                    valueMax={dayLimit.substring(0, 10)}
                    value={value}
                    handleChange={(e) => onChange(e)}
                    TextInput="Data"
                    typeInput={"date"}
                    errorMessage={errors.date?.message}
                  />
                )}
              />
            </ContentInput>
          </ContentForm>
          <ContentButton>
            <Button text="Gerar Senha" typeButton={"submit"} />
          </ContentButton>
        </FormLogin>
      </ContainerLogin>
      <ToPrint
        referency={componentRef}
        active={activePrint}
        setKey={key}
        unitName={selectedUnitName}
      />
    </Content>
  );
}
