import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  height: 100%;
`;

export const Progress = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20%;
  @media (max-width: 1030px) {
    height: 15%;
  }
`;
export const Header = styled.div`
  position: relative;
  display: flex;
`;
export const Body = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  padding: 1rem;
`;
export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0 32px;
  background-color: ${({ theme }) => theme.colors.white};
  margin-right: 15px;
  border-radius: 8px;
`;

export const PanelAttendence = styled.div`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
