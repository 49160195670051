import styled from 'styled-components';

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.secondary};
  background-size: 40px 40px;
  background-image: radial-gradient(#6d6b70 2px, transparent 2px);
`;

export const ContainerKeys = styled.div`
  flex: 1;
  display: grid;
  flex-direction: row;
  grid-template-columns: 3fr 2fr;
  grid-template-rows: 0.2fr 5fr 3fr;
  grid-gap: 20px;
  padding: 10px 20px;
  grid-column-end: ${props => props.showPanel ? 3 : 2 };
  grid-row-end: ${props => props.showPanel ? 10 : 2 };
  position: relative;

  @media screen and (max-width: 768px){
    display: flex;
    flex-direction: column;
  }
`

export const PanelKeys = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: 16px;
  transition: 0.3s;
  display: flex;
  grid-column-start: 1;
  grid-column-end: ${props => props.showPanel ? 3 : 2 };
  grid-row-start: 2;
  grid-row-end: ${props => props.showPanel ? 10 : 2 };
`;

export const ExtraText = styled.div``;

export const ExtraLogo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;

    > div {
      width: 100%;
      justify-content: center;
    }
  }
`;

export const TitleLine = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  overflow: hidden;

  :after, :before {
    content: '';
    display: inline-block;
    height: 2px;
    width: 100%;
    margin: 0;
    background: ${({ theme }) => theme.colors.white};
    text-indent: 20px;
    position: relative;
    vertical-align: middle;
  }

  :after {
    left: .5em;
    margin-right: -100%;
  }

  :before {
    right: .5em;
    margin-left: -100%;
  }
`;

export const ImageLast = styled.img`
  margin-left: 1.5%;
  height: 50px;
  margin: 15px 30px;
`;

export const DateText = styled.div`
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 35px;
  font-weight: 500;
  color: #fff;

  @media screen and (min-width: 500px) {
    text-align: center;
  }
`;

export const LeaseTime = styled.div`
  display: flex;
  border-radius: 16px;
  background-color: ${props => props.theme.colors.white};
`;


export const StartKeysInfo = styled.div`
  display: flex;
  font-size: ${({ newestKey }) => newestKey ? 64 : 32}px;
  flex-direction: column;
  text-align: center;
  grid-column-start: 1;
  grid-column-end: 3;
`;

export const LastKeysInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  grid-column-start: 1;
  grid-column-end: 3;
  text-align: center;
`;

export const LastKeys = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 40px;
  padding: 30px 20px;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 30px 5px;

    > div {
      padding: 10px 5px;
      margin: 10px 0;
    }
  }
`;

export const Pass = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
`;

export const ContainerLease = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 45px;

  @media screen and (max-width: 768px) {
    padding: 10px 5px;
  }
`;

export const Title = styled.span`
  font-size: 2.3rem;
  font-weight: 700;
  color: ${({ cor }) => cor ? cor : props => props.theme.colors.primary};
  text-align: center;
      
  @media screen and (max-width: 768px) {
    font-size: 2rem;
    font-weight: 500;
  }
`;

export const PrevTitle = styled.span`
  font-size: 2.8rem;
  font-weight: 700;
  color: ${({ cor }) => cor ? cor : props => props.theme.colors.primary};
  text-align: center;
  
  @media screen and (max-width: 768px) {
    font-size: 2rem;
    font-weight: 500;
  }
`;

export const KeyActual = styled.span`
  font-size: 4rem;
  font-weight: 700;
  color: ${({ cor }) => cor ? cor : props => props.theme.colors.primary};
  text-align: center;
    
  @media screen and (max-width: 768px) {
    font-size: 3rem;
    font-weight: 500;
  }
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  padding: 10px;
  justify-content: space-between;

  > button {
    /* TODO: Replace button customization */
    background-color: ${({ theme }) => theme.colors.primary};
    margin: 10px 20%;
  }

  > svg {
    align-self: center;
    margin-top: 20px;

    > path {
      stroke: blue;
    }
  }
`;

export const PrevKey = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 6rem;
  font-weight: 700;
  color: ${({ cor }) => cor ? cor : props => props.theme.colors.primary};
  margin: 10px 0;

  @media screen and (max-width: 768px) {
    font-size: 4rem;
  }
`;

export const Key = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  font-weight: 500;
  color: ${({ cor }) => cor ? cor : props => props.theme.colors.primary};
  margin: 10px 0;

  @media screen and (max-width: 768px) {
    font-size: 4rem;
  }
`;

export const KeyCurrent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ newestKey }) => newestKey ? 15 : 8}rem;
  font-weight: 700;
  color: ${({ cor }) => cor ? cor : props => props.theme.colors.primary};
  margin: ${({ newestKey }) => newestKey ? 0 : 10}px 0;
  text-align: center;

`;

export const Info = styled.div`
  display: flex;
  justify-content: space-evenly;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

export const TitleInfo = styled.div`
  font-size: ${({ newestKey }) => newestKey ? 64 : 32}px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  color: ${({ cor }) => cor ? cor : props => props.theme.colors.primary};
`

export const Time = styled.div``

