import React from "react";
import { FaCheckCircle, FaTimes } from "react-icons/fa";

import {
  Content,
  Circle,
  // Text
} from "./styles";

export default function ToggleSelect({ status = true, onClick = undefined }) {
  return (
    <Content onClick={onClick} active={status}>
      <Circle active={status}>
        {status ? (
          <FaCheckCircle color="white" size={16} />
        ) : (
          <FaTimes size={16} color="white" />
        )}
      </Circle>
      {/* <Text></Text> */}
    </Content>
  );
}
