import styled from "styled-components";

export const Content = styled.div`
  flex: 1;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 32px;
`;

export const Title = styled.span`
  font-size: 32px;
`;

export const ContainerTypeAttendance = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerSelectUnit = styled.div`
  display: grid;
  overflow: auto;
  max-height: calc(100vh - 245px); /* TODO: Replace size calc */
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: 1fr;
`;

export const ContainerSelectFila = styled.div`
  width: 100%;
  height: 300px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  padding: 20px;
`;

export const Fila = styled.div`
  width: 100%;
  height: 300px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  padding: 20px;
`;

export const SelectContent = styled.div`
  display: inline-flex;
`;
